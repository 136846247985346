export const routes = {
    login: "/login",
    logout: "/logout",
    signup: "/signup",
    home: "/",
    tasks: "/tasks",
    repos: "/repos",
    secrets: "/secrets",
    integrations: "/integrations",
    integrations_github: "/integrations/github",
    integrations_github_callback: "/integrations/github/callback",
    settings: "/settings",
    settings_workspace: "/settings/workspace",
    settings_users: "/settings/users",
    settings_roles: "/settings/roles",
    settings_secrets: "/settings/secrets",
    settings_integrations: "/settings/integrations",
    settings_switch_workspace: "/settings/switch-workspace",
    account: "/account",
    apps_create: "/apps/create",
    apps_view: "/apps/view",
    reviews: "/reviews",
    infrastructure: "/infrastructure",
    services: "/services",
}

export const localeSettings = {
    localeLanguage: 'en-us',
    localeDateOptions: { year:"numeric", month:"short", day:"numeric"},
    localeTimeOptions: { hour12: true, hour: "numeric", minute: "2-digit" }
}

export const taskTypes = {
    'todo': 'TODO',
    'in_progress': 'IN PROGRESS',
    'review': 'REVIEW',
    'done': 'DONE'
}