// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light', // Use 'dark' for dark mode
    background: {
      default: '#F0F4F8', // Background color
      paper: '#FFFFFF', // Paper background color
    },
    text: {
      primary: '#24292E', // Primary text color
      secondary: '#57606A', // Secondary text color
    },
    primary: {
      main: '#0366D6', // Primary color
      contrastText: '#FFFFFF', // Text color on primary buttons
    },
    secondary: {
      main: '#005CC5', // Secondary color
    },
    divider: '#D1D5DA', // Divider color
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontSize: 16,
  },
});

export default theme;
