export const AWSAssets = require.context('react-aws-icons/dist/aws', true, /\.js$/);

const CloudIcons = {
    "aws": {
        "default": "manage/Instances",
        "resources": {
            "AMI": "compute/AMI",
            "AWSCloud": "compute/AWSCloud",
            "AccessControlList": "compute/AccessControlList",
            "AppLoadBalancer": "compute/AppLoadBalancer",
            "AutoScaling": "compute/AutoScaling",
            "BeanstalkApplication": "compute/BeanstalkApplication",
            "BeanstalkDeployment": "compute/BeanstalkDeployment",
            "C3": "compute/C3",
            "CloudWatch": "compute/CloudWatch",
            "Cluster": "compute/Cluster",
            "Container": "compute/Container",
            "ContainerCPU": "compute/ContainerCPU",
            "ContainerMachine": "compute/ContainerMachine",
            "CustomerGateway": "compute/CustomerGateway",
            "DB": "compute/DB",
            "Download": "compute/Download",
            "ECR": "compute/ECR",
            "EdgeLocation": "compute/EdgeLocation",
            "ElasticIPAddress": "compute/ElasticIPAddress",
            "ElasticNetworkAdapter": "compute/ElasticNetworkAdapter",
            "ElasticNetworkInterface": "compute/ElasticNetworkInterface",
            "Endpoints": "compute/Endpoints",
            "Engine": "compute/Engine",
            "EngineMapRM3": "compute/EngineMapRM3",
            "EngineMapRM5": "compute/EngineMapRM5",
            "EngineMapRM7": "compute/EngineMapRM7",
            "Flowlogs": "compute/Flowlogs",
            "Forums": "compute/Forums",
            "HDFSCluster": "compute/HDFSCluster",
            "HostedZone": "compute/HostedZone",
            "Instance": "compute/Instance",
            "Instances": "compute/Instances",
            "InternetGateway": "compute/InternetGateway",
            "Kinesis": "compute/Kinesis",
            "KinesisAnalytics": "compute/KinesisAnalytics",
            "KinesisFirehose": "compute/KinesisFirehose",
            "KinesisStreams": "compute/KinesisStreams",
            "LambdaFunction": "compute/LambdaFunction",
            "LoadBalancer": "compute/LoadBalancer",
            "ManagementConsole": "compute/ManagementConsole",
            "NATGateway": "compute/NATGateway",
            "Peering": "compute/Peering",
            "Registry": "compute/Registry",
            "Rescue": "compute/Rescue",
            "RouteTable": "compute/RouteTable",
            "Router": "compute/Router",
            "SearchDocuments": "compute/SearchDocuments",
            "Spot": "compute/Spot",
            "SpotFleet": "compute/SpotFleet",
            "Stream": "compute/Stream",
            "VPC": "compute/VPC",
            "VPCSubnet": "compute/VPCSubnet",
            "VPN": "compute/VPN",
            "VPNConnection": "compute/VPNConnection",
            "X1": "compute/X1",
            "Attribute": "db/Attribute",
            "Attributes": "db/Attributes",
            "Cache": "db/Cache",
            "DBM": "db/DBM",
            "DBR": "db/DBR",
            "DBS": "db/DBS",
            "DenseComputeNode": "db/DenseComputeNode",
            "DenseStorageNode": "db/DenseStorageNode",
            "GlobalSecondaryIndex": "db/GlobalSecondaryIndex",
            "Item": "db/Item",
            "Items": "db/Items",
            "MSSQL": "db/MSSQL",
            "MSSQLM": "db/MSSQLM",
            "Memcached": "db/Memcached",
            "Migration": "db/Migration",
            "MySQL": "db/MySQL",
            "MySQLM": "db/MySQLM",
            "Oracle": "db/Oracle",
            "OracleM": "db/OracleM",
            "PIOP": "db/PIOP",
            "PostgreSQL": "db/PostgreSQL",
            "Redis": "db/Redis",
            "SQLM": "db/SQLM",
            "Table": "db/Table",
            "Assignment": "general/Assignment",
            "Client": "general/Client",
            "DB": "general/DB",
            "DataCenter": "general/DataCenter",
            "Disk": "general/Disk",
            "Internet": "general/Internet",
            "InternetCloud": "general/InternetCloud",
            "InternetWifi": "general/InternetWifi",
            "MobileClient": "general/MobileClient",
            "MultiMedia": "general/MultiMedia",
            "OfficeBuilding": "general/OfficeBuilding",
            "Requester": "general/Requester",
            "SAMLToken": "general/SAMLToken",
            "SSLPadlock": "general/SSLPadlock",
            "Server": "general/Server",
            "TapeStorage": "general/TapeStorage",
            "Task": "general/Task",
            "User": "general/User",
            "Users": "general/Users",
            "Workers": "general/Workers",
            "Action": "iot/Action",
            "Actuator": "iot/Actuator",
            "AlexaSkill": "iot/AlexaSkill",
            "Certificate": "iot/Certificate",
            "DesiredState": " iot/DesiredState",
            "Echo": "iot/Echo",
            "FireTV": "iot/FireTV",
            "FireTVStick": "iot/FireTVStick",
            "HTTP": "iot/HTTP",
            "HTTP2": "iot/HTTP2",
            "HardwareBoard": "iot/HardwareBoard",
            "LambdaFunction": "iot/LambdaFunction",
            "MQTT": "iot/MQTT",
            "Policy": "iot/Policy",
            "ReportedState": "iot/ReportedState",
            "Rule": "iot/Rule",
            "Sensor": "iot/Sensor",
            "Servo": "iot/Servo",
            "Shadow": "iot/Shadow",
            "Simulator": "iot/Simulator",
            "SmartHomeSkill": "iot/SmartHomeSkill",
            "Topic": "iot/Topic",
            "VoiceService": "iot/VoiceService",
            "ACM": "logo/ACM",
            "APIGateway": "logo/APIGateway",
            "AWS": "logo/AWS",
            "AppStream": "logo/AppStream",
            "ApplicationDiscoveryService": "logo/ApplicationDiscoveryService",
            "Artifact": "logo/Artifact",
            "Athena": "logo/Athena",
            "Batch": "logo/Batch",
            "Chime": "logo/Chime",
            "CloudDirectory": "logo/CloudDirectory",
            "CloudFormation": "logo/CloudFormation",
            "CloudFront": "logo/CloudFront",
            "CloudHSM": "logo/CloudHSM",
            "CloudSearch": "logo/CloudSearch",
            "CloudTrail": "logo/CloudTrail",
            "CloudWatch": "logo/CloudWatch",
            "CodeBuild": "logo/CodeBuild",
            "CodeCommit": "logo/CodeCommit",
            "CodeDeploy": "logo/CodeDeploy",
            "CodePipeline": "logo/CodePipeline",
            "CodeStar": "logo/CodeStar",
            "Cognito": "logo/Cognito",
            "Config": "logo/Config",
            "Connect": "logo/Connect",
            "DMS": "logo/DMS",
            "DataPipeline": "logo/DataPipeline",
            "DeviceFarm": "logo/DeviceFarm",
            "DirectConnect": "logo/DirectConnect",
            "DirectoryService": "logo/DirectoryService",
            "DynamoDB": "logo/DynamoDB",
            "DynamoDBAccelerator": "logo/DynamoDBAccelerator",
            "EC2": "logo/EC2",
            "ECS": "logo/ECS",
            "EFS": "logo/EFS",
            "ELB": "logo/ELB",
            "EMR": "logo/EMR",
            "ES": "logo/ES",
            "ElasticBeanstalk": "logo/ElasticBeanstalk",
            "ElasticCache": "logo/ElasticCache",
            "ElasticTranscoder": "logo/ElasticTranscoder",
            "GameList": "logo/GameList",
            "Glacier": "logo/Glacier",
            "Glue": "logo/Glue",
            "Greengrass": "logo/Greengrass",
            "IAM": "logo/IAM",
            "Inspector": "logo/Inspector",
            "IoT": "logo/IoT",
            "KMS": "logo/KMS",
            "Kinesis": "logo/Kinesis",
            "Lambda": "logo/Lambda",
            "Lex": "logo/Lex",
            "Lightsail": "logo/Lightsail",
            "MachineLearning": "logo/MachineLearning",
            "Macie": "logo/Macie",
            "ManagedServices": "logo/ManagedServices",
            "MechanicalTurk": "logo/MechanicalTurk",
            "MigrationHub": "logo/MigrationHub",
            "MobileAnalytics": "logo/MobileAnalytics",
            "MobileHub": "logo/MobileHub",
            "OpsWorks": "logo/OpsWorks",
            "Organizations": "logo/Organizations",
            "Pinpoint": "logo/Pinpoint",
            "Polly": "logo/Polly",
            "QuickSight": "logo/QuickSight",
            "RDS": "logo/RDS",
            "Redshift": "logo/Redshift",
            "Rekognition": "logo/Rekognition",
            "Route53": "logo/Route53",
            "S3": "logo/S3",
            "SES": "logo/SES",
            "SMS": "logo/SMS",
            "SNS": "logo/SNS",
            "SQS": "logo/SQS",
            "SWF": "logo/SWF",
            "ServiceCatalog": "logo/ServiceCatalog",
            "Shield": "logo/Shield",
            "Snowball": "logo/Snowball",
            "StepFunctions": "logo/StepFunctions",
            "StorageGateway": "logo/StorageGateway",
            "SystemsManager": "logo/SystemsManager",
            "TrustedAdvisor": "logo/TrustedAdvisor",
            "VPC": "logo/VPC",
            "WAF": "logo/WAF",
            "XRay": "logo/XRay",
            "Apps": "manage/Apps",
            "Automation": "manage/Automation",
            "Changeset": "manage/Changeset",
            "Checklist": "manage/Checklist",
            "ChecklistCost": "manage/ChecklistCost",
            "ChecklistFaultTolerance": "manage/ChecklistFaultTolerance",
            "ChecklistPerformance": "manage/ChecklistPerformance",
            "ChecklistSecurity": "manage/ChecklistSecurity",
            "Deployments": "manage/Deployments",
            "Documents": "manage/Documents",
            "EventAlarm": "manage/EventAlarm",
            "EventEventBased": "manage/EventEventBased",
            "EventTimeBased": "manage/EventTimeBased",
            "Filter": "manage/Filter",
            "Instances": "manage/Instances",
            "Inventory": "manage/Inventory",
            "Layers": "manage/Layers",
            "MaintenanceWindow": "manage/MaintenanceWindow",
            "Monitoring": "manage/Monitoring",
            "ParameterStore": "manage/ParameterStore",
            "PatchManager": "manage/PatchManager",
            "Permissions": "manage/Permissions",
            "Resources": "manage/Resources",
            "Rule": "manage/Rule",
            "RunCommand": "manage/RunCommand",
            "Stack": "manage/Stack",
            "StateManager": "manage/StateManager",
            "Template": "message/manage/Template",
            "Decider": "message/Decider",
            "Email": "message/Email",
            "EmailNotification": "message/EmailNotification",
            "HTTPNotification": "message/HTTPNotification",
            "Message": "message/Message",
            "Queue": "message/Queue",
            "Topic": "message/Topic",
            "Worker": "message/Worker",
            "Android": "sdk/Android",
            "CLI": "sdk/CLI",
            "Eclipse": "sdk/Eclipse",
            "Java": "sdk/Java",
            "JavaScript": "sdk/JavaScript",
            "Net": "sdk/Net",
            "NodeJS": "sdk/NodeJS",
            "PHP": "sdk/PHP",
            "PowerShell": "sdk/PowerShell",
            "Python": "sdk/Python",
            "Ruby": "sdk/Ruby",
            "VisualStudio": "sdk/VisualStudio",
            "Xamerin": "sdk/Xamerin",
            "iOS": "sdk/iOS",
            "ACM": "security/ACM",
            "DataEncryptionKey": "security/DataEncryptionKey",
            "EncryptedData": "security/EncryptedData",
            "IAM": "security/IAM",
            "Inspector": "security/Inspector",
            "LongTermCredential": "security/LongTermCredential",
            "MFAToken": "security/MFAToken",
            "Permissions": "security/Permissions",
            "Role": "security/Role",
            "STS": "security/STS",
            "STSToken": "security/STSToken",
            "TemporaryCredential": "security/TemporaryCredential",
            "Bucket": "storage/Bucket",
            "BucketWithObjects": "storage/BucketWithObjects",
            "CachedVolume": "storage/CachedVolume",
            "EBS": "storage/EBS",
            "EFSFilesystem": "storage/EFSFilesystem",
            "GlacierArchive": "storage/GlacierArchive",
            "GlacierVault": "storage/GlacierVault",
            "ImportExport": "storage/ImportExport",
            "NonCachedVolume": "storage/NonCachedVolume",
            "Object": "storage/Object",
            "Snapshot": "storage/Snapshot",
            "VirtualTapeLibrary": "storage/VirtualTapeLibrary",
            "Volume": "storage/Volume",
            "AVS": "thing/AVS",
            "Bank": "thing/Bank",
            "Bicycle": "thing/Bicycle",
            "Camera": "thing/Camera",
            "Car": "thing/Car",
            "Cart": "thing/Cart",
            "Coffeepot": "thing/Coffeepot",
            "DoorLock": "thing/DoorLock",
            "Factory": "thing/Factory",
            "Generic": "thing/Generic",
            "House": "thing/House",
            "LightBulb": "thing/LightBulb",
            "Medical": "thing/Medical",
            "Police": "thing/Police",
            "Thermostat": "thing/Thermostat",
            "Travel": "thing/Travel",
            "Utility": "thing/Utility",
            "WindFarm": "thing/WindFarm",
            "Docs": "Docs",
            "Mail": "Mail",
            "Spaces": "Spaces"
        }
    },
    "azure": { },
    "gcp": { }
}

export default CloudIcons;