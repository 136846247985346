import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { fetchWorkspacesByUserId, patchAccounts } from '../../api/integrations';
import { AuthContext } from "../../context/AuthContext";
import CancelButton from '../../components/Shared/CancelButton';
import SuccessButton from '../../components/Shared/SuccessButton';
import { routes } from '../../utils/Constants';

function SwitchWorkspaceModal() {
  const navigate = useNavigate();
  const { user, currentWorkspace, setCurrentWorkspace } = React.useContext(AuthContext);
  const [workspace, setWorkspace] = React.useState(currentWorkspace);
  const [workspaces, setWorkspaces] = React.useState([]);
  const [isError, setIsError] = React.useState(false);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSwitch = async () => {
    try {
      await patchAccounts(user.id, workspace);
      setIsError(false);
      setCurrentWorkspace(workspace);
      navigate(routes.settings_workspace);
    } catch (error) {
      console.error('Failed to update last workspace', error);
      setIsError(true);
    }
  };

  const handleChange = (event) => {
    setWorkspace(event.target.value);
  };

  const loadInfo = async () => {
    try {
        const response = await fetchWorkspacesByUserId(user.id);
        
        if (response.status === 200) {
          const { workspaces } = response.data;
          setWorkspaces(workspaces);
        }
    } catch (error) {
        console.error('Failed to fetch workspaces data', error);
    }
  }

  React.useEffect(() => {
    loadInfo();
  }, [])

  return (
    <Dialog fullWidth open={true} onClose={handleCancel}>
        <DialogTitle textAlign={'center'}>Switch Workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Workspaces</p>
            {
              isError && <span style={{fontSize: '12px', color: 'red'}}>There was an error updating the workspace</span>
            }
            <FormControl fullWidth>
              <Select value={workspace} onChange={handleChange} displayEmpty>
                {workspaces.map((item) => (
                  <MenuItem key={item.ID} value={item.ID}>{item.name}</MenuItem>
                ))}
              </Select>
          </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'center', gap: '24px', padding: '24px'}}>
          <CancelButton variant="contained" onClick={handleCancel}>Cancel</CancelButton>
          <SuccessButton variant="contained" onClick={handleSwitch} autoFocus>Switch</SuccessButton>
        </DialogActions>
    </Dialog>
  );
}

export default SwitchWorkspaceModal;