import React from 'react';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Banner = ({ message, severity, onClose }) => {
  return (
    <Alert
      severity={severity} // 'success' | 'info' | 'warning' | 'error';
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      sx={{ mb: 2 }}
    >
      <AlertTitle>{severity === 'success' ? 'Success' : 'Error'}</AlertTitle>
        {message}
    </Alert>
  );
};

export default Banner;
