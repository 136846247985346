import React, {useContext, useState} from 'react';
import {
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';
import {GlobalContext} from "../../context/Provider";
import {useNavigate} from "react-router-dom";

const now = new Date(); // This captures the current date and time
const currentDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
const formattedDate = `${currentDate.getUTCMonth() + 1}/${currentDate.getUTCDate()}/${currentDate.getUTCFullYear()}`;

function AppForm() {
    const navigate = useNavigate();

    const {setNewApp, apps, setApps} = useContext(GlobalContext);
    const [appName, setAppName] = useState('');
    const [archetype, setArchetype] = useState('');
    const [deploymentSettings, setDeploymentSettings] = useState('Recommended');
    const [reviewMRs, setReviewMRs] = useState(true);

    const handleDashboardRedirect = () => {
        navigate('/');
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        setNewApp({
            appName,
            archetype,
            deploymentSettings,
            reviewMRs: reviewMRs ? 'Yes' : 'No'
        });
        setApps([
            ...apps,
            {
                name: appName,
                createDt: formattedDate,
                status: "deploying"
            }
        ]);

        handleDashboardRedirect()
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                label="App Name"
                variant="outlined"
                value={appName}
                onChange={e => setAppName(e.target.value)}
                fullWidth
                margin="normal"
            />
            <FormControl fullWidth margin="normal">
                <InputLabel>Archetype</InputLabel>
                <Select
                    value={archetype}
                    label="Archetype"
                    onChange={e => setArchetype(e.target.value)}
                >
                    <MenuItem value="Golang">Golang</MenuItem>
                    <MenuItem value="Javascript">Javascript</MenuItem>
                    <MenuItem value="C#">C#</MenuItem>
                    <MenuItem value="Java">Java</MenuItem>
                </Select>
            </FormControl>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={deploymentSettings === 'Recommended'}
                                     onChange={e => setDeploymentSettings(e.target.checked ? 'Recommended' : 'Advanced')}/>}
                    label={`Deployment Settings (e.g Networking resources, CICD, etc): ${deploymentSettings}`}
                />
            </FormGroup>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={reviewMRs} onChange={e => setReviewMRs(e.target.checked)}/>}
                    label={`Review MRs before they are deployed: ${reviewMRs ? 'Yes' : 'No'}`}
                />
            </FormGroup>
            <Button type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </form>
    );
}

export default AppForm;
