import React from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import { IconButton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableComponent from "../../../components/Shared/TableComponent";
import { AuthContext } from "../../../context/AuthContext";
import { fetchIntegrationsByWorkspaceId, fetchRolesByWorkspaceId } from "../../../api/integrations";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    },
    statusContainer: { width: '100%', display: 'flex', justifyContent: 'center' },
    statusBadge: { width: '16px', height: '16px', borderRadius: '8px' },
    actionContainer: { width: '100%', display: 'flex', justifyContent: 'center' }
}));

const headers = ["ID", "Integration", "Type", "Status", ""]

function IntegrationsPage({title}) {
    const classes = useStyles();
    const { currentWorkspace } = React.useContext(AuthContext);
    const [rows, setRows] = React.useState([]);

    const handleAction = (e) => {
        /** Not implemented */
    };

    const loadInfo = async () => {
        try {
            const response = await fetchIntegrationsByWorkspaceId(currentWorkspace);
            
            if (response.status === 200) {
                const { integrations } = response.data;
                const rows = integrations.map((x) => {
                    return {
                        ID: x.id,
                        Integration: x.reference_type,
                        Type: x.integration_type,
                        Status: (
                            <div className={classes.statusContainer}>
                                <div className={classes.statusBadge} style={{ backgroundColor: x.is_active ? '#05FF00' : 'red' }}/>
                            </div>
                        ),
                        Action: (
                            <IconButton
                                className={classes.actionContainer}
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleAction}
                            >
                              <ChevronRightIcon sx={{fontSize: 28}} />
                            </IconButton>
                        )
                    }
                });
                setRows(rows);
            }

        } catch (error) {
            console.error('Failed to fetch integrations data', error);
        }
    }

    React.useEffect(() => {
        loadInfo();
    }, [])

    return (
        <div>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
            <Container maxWidth='md'>
                <TableComponent headers={headers} rows={rows}/>
            </Container>
        </div>
    );
}

export default IntegrationsPage;