import axios from "axios";

export const fetchInfrastructureEvents = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_INFRASTRUCTURE_URL}/infrastructure/events`, {
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching infrastructure events:", error);
        throw error;
    }
};

export const fetchInfrastructureEventItem = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_INFRASTRUCTURE_URL}/infrastructure/events/item`, {
            params: {
                id: id
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching infrastructure event item:", error);
        throw error;
    }
};

export const fetchInfrastructureRelationships = async (user_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_INFRASTRUCTURE_URL}/infrastructure/relationships`, {
            params: {
                user_id: user_id
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching infrastructure relationships:", error);
        throw error;
    }
};