import React from 'react';
import {
    Typography,
    Grid,
    Box,
    Stack,
    OutlinedInput,
    InputAdornment,
    IconButton
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditOffIcon from '@mui/icons-material/EditOff';
import CancelButton from '../../../components/Shared/CancelButton';
import SuccessButton from '../../../components/Shared/SuccessButton';
import { AuthContext } from "../../../context/AuthContext";
import { fetchWorkspacesById, fetchWorkspaceUsersByWorkspaceId } from "../../../api/integrations";

function WorkspaceForm() {
    const defaultWorkspace = 'dbfd3ff9-fba1-4ac1-9249-9a4b8d60c32e';
    const { currentWorkspace } = React.useContext(AuthContext);
    /** Temporary data */
    const [workspace, setWorkspace] = React.useState({ ID: '-', name: '-', created_at: '-', workspace_type: '-', is_active: false, updated_at: '-' });
    const [userCount, setUserCount] = React.useState(0);
    const [editName, setEditName] = React.useState(false);

    const nameDisabled = () => workspace.ID === defaultWorkspace;

    const onChange = (key, value) => {
        setWorkspace({...workspace, [key]: value })
    }

    const handleEditName = (e) => {
        setEditName(!editName);
    }

    const handleSubmit = (e) => {
        /** Not implemented */
    };

    const loadInfo = async () => {
        try {
            const [responseWorkspaces, responseWorkspaceUsers] = await Promise.allSettled([
                fetchWorkspacesById(currentWorkspace),
                fetchWorkspaceUsersByWorkspaceId(currentWorkspace)
            ]);
            const _responseWorkspaces = responseWorkspaces.status === 'fulfilled' ? responseWorkspaces.value : {};
            const _responseWorkspaceUsers = responseWorkspaceUsers.status === 'fulfilled' ? responseWorkspaceUsers.value :  {};
            
            if (_responseWorkspaces.status === 200) {
                const { workspaces } = _responseWorkspaces.data;
                if (workspaces.length > 0) setWorkspace(workspaces[0]);
            }

            if (_responseWorkspaceUsers.status === 200) {
                const { count } = _responseWorkspaceUsers.data;
                setUserCount(count);
            }

        } catch (error) {
            console.error('Failed to fetch Workspace data', error);
        }
    }

    React.useEffect(() => {
        loadInfo();
    }, [currentWorkspace])

    return (
        <Box margin={'32px 52px 32px 52px'} padding={'48px'} bgcolor={'white'}>
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={4} alignItems={'center'} spacing={2}>
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Typography variant="body1">ID</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">{workspace.ID}</Typography>
                        </Grid>
                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Typography variant="body1">Name</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <OutlinedInput value={workspace.name} 
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleEditName}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                            disabled={!nameDisabled()}
                                        >
                                            {editName ? <EditOffIcon /> : <ModeEditIcon /> }
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => onChange('name', e.target.value) }
                                size='small'
                                sx={{fontSize: '16px'}}
                                readOnly={!editName}
                                disabled={nameDisabled()}
                            />
                        </Grid>

                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Typography variant="body1">Created</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">{new Date(workspace.created_at).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}</Typography>
                        </Grid>
                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Typography variant="body1">Status</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">{workspace.is_active ? 'Active' : 'Inactive' }</Typography>
                        </Grid>
                    </React.Fragment>
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Typography variant="body1">Users</Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">{userCount}</Typography>
                        </Grid>
                    </React.Fragment>
                </Grid>
                <Stack marginTop={'24px'} direction="row" spacing={2}>
                    <CancelButton variant="contained">
                        Cancel
                    </CancelButton>
                    <SuccessButton type="submit" variant="contained">
                        Save
                    </SuccessButton>
                </Stack>
                <div style={{marginTop: "32px", borderWidth: "1px", borderStyle: "solid", borderColor: "#F37A7A", borderRadius: "10px", padding: "12px", display: 'flex', alignItems: 'center'}}>
                    <Box flex={3}>
                        <Typography variant="h6">Delete this workspace?</Typography>
                        <Typography variant="body1" color={"#8E8E8E"}>Deleting this workspace will remove all repositories, apps, 
                            integrations, secrets, roles, and users associated with this workspace.
                        </Typography>
                    </Box>
                    <Box display={'flex'} flex={1} justifyContent={'center'}>
                        <CancelButton variant="contained">
                            Delete
                        </CancelButton>
                    </Box>
                </div>
            </form>
        </Box>
    );
}

export default WorkspaceForm;
