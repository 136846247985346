import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const LogoutPage = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isLoggingOut, setIsLoggingOut] = useState(true);

    useEffect(() => {
        logout().then(() => {
            setIsLoggingOut(false);
            navigate('/login');
        });
    }, [logout, navigate]);

    if (isLoggingOut) {
        return <div>Logging out...</div>;
    }

    return null;
};

export default LogoutPage;