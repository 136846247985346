import React from 'react';
import {Routes, Route, Outlet, useLocation} from 'react-router-dom';
import {Box, Typography} from "@mui/material";
import {createUseStyles} from 'react-jss';
import RepositoriesPage from "./pages/RepositoriesPage";
import CreateApp from "./pages/CreateApp";
import HomePage from "./pages/HomePage";
import TasksPage from "./pages/TasksPage";
import SettingsPage from "./pages/SettingsPage";
import LogoutPage from "./pages/LogoutPage";
import SignUpPage from "./pages/SignupPage";
import IntegrationsPage from "./pages/IntegrationsPage";
import GithubIntegrationPage from "./pages/GithubIntegrationPage";
import GithubIntegrationOauthCallback from "./pages/GithubIntegrationOauthCallbackPage";
import AccountPage from './pages/AccountPage';
import ViewApp from "./pages/ViewApp";
import LoginPage from "./pages/LoginPage";
import Navbar from "./components/Shared/Navbar";
import PrivateRoute from "./navigation/PrivateRoute";
import './App.css';
import { routes } from './utils/Constants';
import { WorkspacePage, UsersPage, RolesPage, SecretsPage, IntegrationsPage as SettingsIntegrationsPage, SwitchWorkspaceModal } from './pages/SettingsPage';
import ReviewsPage from './pages/ReviewsPage';
import InfrastructurePage from './pages/InfrastructurePage';
import ServicesPage from './pages/ServicesPage';

const useStyles = createUseStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    content: {
        flex: 1,
        paddingLeft: 250,
        display: 'flex',
        flexDirection: 'column',
    }
}));

function Footer() {
    return (
        <Box component="footer" sx={{mt: 'auto', py: 2, textAlign: 'center', width: '100%'}}>
            <Typography variant="body2">
                © {new Date().getFullYear()} Ada Labs LLC. All rights reserved.
            </Typography>
        </Box>
    );
}

function App() {
    const classes = useStyles();
    const location = useLocation();
    const previousLocation = location.state?.previousLocation;

    return (
        <React.Fragment>
            <Routes location={previousLocation || location}>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/logout" element={<LogoutPage/>}/>
                {/*<Route path="/signup" element={<SignUpPage/>}/>*/}
                <Route element={<PrivateRoute><ProtectedLayout classes={classes}/></PrivateRoute>}>
                    <Route path={routes.home} element={<HomePage title="Home"/>}/>
                    <Route path={routes.tasks} element={<TasksPage title="Tasks"/>}/>
                    <Route path={routes.services} element={<ServicesPage title="Services"/>}/>
                    <Route path={routes.infrastructure} element={<InfrastructurePage title="Infrastructure"/>}/>
                    <Route path={routes.reviews} element={<ReviewsPage title="Reviews"/>}/>
                    <Route path={routes.repos} element={<RepositoriesPage title="Repositories"/>}/>
                    <Route path={routes.secrets} element={<SecretsPage title="Secrets"/>}/>
                    <Route path={routes.integrations} element={<IntegrationsPage title="Integrations"/>}/>
                    <Route path={routes.integrations_github} element={<GithubIntegrationPage title="Github Integrations"/>}/>
                    <Route path={routes.integrations_github_callback} element={<GithubIntegrationOauthCallback />}/>
                    <Route path={routes.settings} element={<SettingsPage title="Settings"/>}>
                        <Route index element={<WorkspacePage title="Workspace"/>}/>
                        <Route path={routes.settings_workspace} element={<WorkspacePage title="Workspace"/>}/>
                        <Route path={routes.settings_users} element={<UsersPage title="Users"/>}/>
                        <Route path={routes.settings_roles} element={<RolesPage title="Roles"/>}/>
                        <Route path={routes.settings_secrets} element={<SecretsPage title="Secrets"/>}/>
                        <Route path={routes.settings_integrations} element={<SettingsIntegrationsPage title="Integrations"/>}/>
                    </Route>
                    <Route path={routes.account} element={<AccountPage title="Account"/>}/>
                    <Route path={routes.apps_create} element={<CreateApp/>}/>
                    <Route path={routes.apps_view} element={<ViewApp/>}/>
                </Route>
            </Routes>
            {previousLocation && (
                <Routes>
                    <Route path={routes.settings_switch_workspace} element={<SwitchWorkspaceModal />} />
                </Routes>
            )}
        </React.Fragment>
    );
}

function ProtectedLayout({classes}) {
    return (
        <div className={classes.root}>
            <Navbar/>
            <div className={classes.content}>
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
