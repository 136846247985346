import React from 'react';
import {createUseStyles} from 'react-jss';
import { useDrag } from 'react-dnd'

const useStyles = createUseStyles((theme) => ({
    cardContainer: { backgroundColor: '#fff', borderRadius: 8, width: '100%', cursor: 'move' },
    cardTitle: { display: 'flex', alignItems: 'center', padding: '4px 16px 0px', fontWeight: '200', fontSize: '8px' },
    cardTitleAsigned: { display: 'flex', flex: 1, alignItems: 'center', gap: 4 },
    cardTitleEstimated: { },
    cardBody: { fontSize: '12px', padding: '0px 16px' },
    cardFooter: { display: 'flex', alignItems: 'center', padding: '4px 16px 8px', fontWeight: '200', fontSize: '8px' },
    cardFooterWorkflow: { display: 'flex', flex: 1, alignItems: 'center', gap: 4 },
    cardFooterWorkflowMark: { backgroundColor: '#7DFBFB', padding: '0px 4px', borderRadius: '4px', color: '#000' },
    cardFooterView: { backgroundColor: '#01BFBF', padding: '4px 8px', borderRadius: '4px', color: '#fff', fontSize: '8px', fontWeight: '600', cursor: 'pointer' },
}));

const TaskCard = ({id, type}) => {
    const classes = useStyles();
    const [{ opacity }, drag] = useDrag(
        () => ({
          type,
          item: { id },
          collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
          }),
        }),
        [id, type],
    )

    return (
        <div ref={drag} className={classes.cardContainer} style={{opacity}}>
            <div className={classes.cardTitle}>
                <div className={classes.cardTitleAsigned}>
                    <span>Asigned:</span>
                    <img src={`${process.env.PUBLIC_URL}/images/adalabs-color.png`} width={16} />
                </div>
                <span>Estimate: <b>5</b></span>
            </div>
            <div className={classes.cardBody}>
                <span>Integrate accounts service with Auth0 and update the web app</span>
            </div>
            <div className={classes.cardFooter}>
                <div className={classes.cardFooterWorkflow}>
                    <span>Workflow:</span>
                    <div className={classes.cardFooterWorkflowMark}><span>Auth0 Integration</span></div>
                </div>
                <span className={classes.cardFooterView}>View</span>
            </div>
        </div>
    );
}

export default TaskCard;