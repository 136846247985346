import React from 'react';
import {Container} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {
    ReactFlow,
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import VirtualizedTable, { TableRowsType } from '../components/NewAppComponents/VirtualizedTable';
import { nodeTypes } from '../components/Nodes';

const useStyles = createUseStyles((theme) => ({
  container: {
      padding: '48px'
  },
  title: { backgroundColor: '#000', color: '#fff', padding: '8px 12px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' },
  diagramContainer: { padding: '16px', backgroundColor: '#fff' },
  diagramSubContainer: { border: '1px solid #000', height: '250px', borderRadius: '8px' },
  dialogTitle: { padding: '8px', backgroundColor: '#01BFBF', fontSize: '20px', fontWeight: '600', color: '#fff', borderRadius: '8px', flex: 1 },
  tabTitleContainer: { color: '#fff !important', fontSize: '20px !important', textTransform: 'none', borderTopLeftRadius: 16, borderTopRightRadius: 16 },
  tabContentTitle: { fontWeight: 'bold' },
  tabContentImportant:  { color: '#05B6B7', fontWeight: '600' },
  tabDetailContainer: { borderRadius: '8px', border: '1px solid #000', width: '240px' },
  tabDetailTitleContainer: { backgroundColor: '#000', borderTopRightRadius: '8px', borderTopLeftRadius: '8px', textAlign: 'center', padding: '4px 36px', fontSize: '20px' },
  tabDetailContentGroup: { display: 'flex', gap: '4', margin: '8px 0px' },
  tabDetailContentGroupField: { flex: 1, fontWeight: '600' },
  tabDetailContentGroupValue: { flex: 1, textAlign: 'center' },
  tabRequestContentGroupTitle: { borderBottom: '1px solid #000', fontWeight: '700', fontSize: '16px' },
  tabRequestContentGroupField: { fontWeight: '600' }
}));

const saInitialNodes = [
  {
    id: '1',
    type: 'source',
    position: { x: 0, y: 0 },
    data: {
      description: 'web',
      icon: 'reactjs'
    }
  },
  {
    id: '2',
    type: 'action',
    position: { x: 150, y: 0 },
    data: {
      description: 'graph',
      icon: 'graphql'
    }
  },
  {
    id: '3',
    type: 'action',
    position: { x: 350, y: -100 },
    data: {
      description: 'user',
      icon: 'golang'
    }
  },
  {
    id: '4',
    type: 'action',
    position: { x: 350, y: -30 },
    data: {
      description: 'auth',
      icon: 'golang'
    }
  },
  {
    id: '5',
    type: 'action',
    position: { x: 350, y: 30 },
    data: {
      description: 'accounts',
      icon: 'golang'
    }
  },
  {
    id: '6',
    type: 'action',
    position: { x: 350, y: 100 },
    data: {
      description: 'transactions',
      icon: 'golang'
    }
  },
  {
    id: '7',
    type: 'action',
    position: { x: 550, y: -100 },
    data: {
      description: 'auth0 api',
      icon: 'oauth'
    }
  },
  {
    id: '8',
    type: 'action',
    position: { x: 550, y: -30 },
    data: {
      description: 'postgres',
      icon: 'postgresql'
    }
  },
  {
    id: '9',
    type: 'action',
    position: { x: 550, y: 60 },
    data: {
      description: 'plaid api',
      icon: 'plaid'
    }
  },
];
  
const saInitialEdges = [
  {
    id: 'e1-2',
    source: '1',
    target: '2',
    sourceHandle: 'a',
    targetHandle: 'b',
    type: 'straight'
  },
  {
    id: 'e2-3',
    source: '2',
    target: '3',
    sourceHandle: 'a',
    targetHandle: 'b',
    type: 'straight'
  },
  {
    id: 'e3-4',
    source: '3',
    target: '4',
    sourceHandle: 'b',
    targetHandle: 'a',
    type: 'straight'
  },
  {
    id: 'e2-5',
    source: '2',
    target: '5',
    sourceHandle: 'a',
    targetHandle: 'b',
    type: 'straight'
  },
  {
    id: 'e2-6',
    source: '2',
    target: '6',
    sourceHandle: 'a',
    targetHandle: 'b',
    type: 'straight'
  },
  {
    id: 'e3-7',
    source: '3',
    target: '7',
    sourceHandle: 'a',
    targetHandle: 'b',
    animated: true
  },
  {
    id: 'e3-8',
    source: '3',
    target: '8',
    sourceHandle: 'a',
    targetHandle: 'b',
    animated: true
  },
  {
    id: 'e4-8',
    source: '4',
    target: '8',
    sourceHandle: 'a',
    targetHandle: 'b',
    animated: true
  },
  {
    id: 'e5-8',
    source: '5',
    target: '8',
    sourceHandle: 'a',
    targetHandle: 'b',
    animated: true
  },
  {
    id: 'e6-8',
    source: '6',
    target: '8',
    sourceHandle: 'a',
    targetHandle: 'b',
    animated: true
  },
  {
    id: 'e5-9',
    source: '5',
    target: '9',
    sourceHandle: 'a',
    targetHandle: 'b',
    animated: true
  },
  {
    id: 'e6-9',
    source: '6',
    target: '9',
    sourceHandle: 'a',
    targetHandle: 'b',
    animated: true
  },
];

const columns = [
  {
    width: 100,
    label: 'ID',
    dataKey: 'id',
    align: 'center'
  },
  {
    label: 'Title',
    dataKey: 'title',
  },
  {
    width: 100,
    label: 'Author',
    dataKey: 'author',
    align: 'center'
  },
  {
    width: 100,
    label: 'Repo',
    dataKey: 'repo'
  },
  {
    width: 160,
    label: 'Created',
    dataKey: 'created',
    align: 'center'
  },
  {
    width: 100,
    label: 'Impact',
    dataKey: 'impact',
    align: 'center'
  },
  {
    width: 140,
    label: 'Severity',
    dataKey: 'severity',
    align: 'center'
  }
];

const rows = [
  {
    id: '1',
    title: 'Add property service /listing api', 
    author: <TableRowsType.Avatar src={"/static/images/avatar/1.jpg"} profile_url={"https://www.google.com"}/>,
    repo: <TableRowsType.Repo name={"Property"} url={"https://www.google.com"}/>,
    created: '07/04/24 7:24 PM',
    impact: <TableRowsType.Impact level={3}/>,
    severity: <TableRowsType.Severity severity={'Critical'}/>
  },
  {
    id: '2',
    title: 'Create property listing table in Postgres', 
    author: <TableRowsType.Avatar src={"/static/images/avatar/1.jpg"} profile_url={"https://www.google.com"}/>,
    repo: <TableRowsType.Repo name={"Property"} url={"https://www.google.com"}/>,
    created: '07/04/24 7:24 PM',
    impact: <TableRowsType.Impact level={0}/>,
    severity: <TableRowsType.Severity severity={'None'}/>
  },
  {
    id: '3',
    title: 'Add GET /listing contracts', 
    author: <TableRowsType.Avatar src={"/static/images/avatar/1.jpg"} profile_url={"https://www.google.com"}/>,
    repo: <TableRowsType.Repo name={"Property"} url={"https://www.google.com"}/>,
    created: '07/04/24 7:24 PM',
    impact: <TableRowsType.Impact level={1}/>,
    severity: <TableRowsType.Severity severity={'Warning'}/>
  }
];

function ServicesPage({title}) {
    const classes = useStyles();
    const [saNodes, setSANodes] = React.useState(saInitialNodes);
    const [saEdges, setSAEdges] = React.useState(saInitialEdges);
    const [selectedItem, setSelectedItem] = React.useState({});

    const onSANodesChange = React.useCallback(
      (changes) => setSANodes((nds) => applyNodeChanges(changes, nds)),
      [],
    );

    const onSAEdgesChange = React.useCallback(
        (changes) => setSAEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );

    const onSAConnect = React.useCallback(
        (params) => setSAEdges((eds) => setSAEdges(params, eds)),
        [],
    );

    const handleClick = (item, index) => {
      setSelectedItem({item, index});
    }

    return (
        <div className={classes.container}>
          <Container fixed>
              <div className={classes.title}>
                <span>Service Architecture</span>
              </div>
              <div className={classes.diagramContainer}>
                <div className={classes.diagramSubContainer}>
                  <ReactFlow
                      nodes={saNodes}
                      nodeTypes={nodeTypes}
                      onNodesChange={onSANodesChange}
                      edges={saEdges}
                      onEdgesChange={onSAEdgesChange}
                      onConnect={onSAConnect}
                      fitView
                  >
                      <Background />
                      <Controls />
                  </ReactFlow>
                </div>
              </div>
              <div style={{height: "36px"}}/>
              <VirtualizedTable columns={columns} data={rows} sx={{height: 300}} tableCellStyle={{lineHeight: '24px'}} rowContentStyle={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'}} hover onClick={handleClick}/>
          </Container>
        </div>
    );
}

export default ServicesPage;