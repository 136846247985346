import React from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import { Delete } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";
import TableComponent from "../../../components/Shared/TableComponent";
import PrimaryButton from '../../../components/Shared/PrimaryButton';
import { AuthContext } from "../../../context/AuthContext";
import { fetchWorkspaceUsersByWorkspaceId } from "../../../api/integrations";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    }
}));

const headers = ["Name", "Email", "Role", ""]

function UsersPage({title}) {
    const classes = useStyles();
    const { currentWorkspace } = React.useContext(AuthContext);
    const [rows, setRows] = React.useState([]);

    const handleOpen = (e) => {
        /** Not implemented */
    }

    const handleDelete = (e) => {
        /** Not implemented */
    }

    const loadInfo = async () => {
        try {
            const response = await fetchWorkspaceUsersByWorkspaceId(currentWorkspace);
            
            if (response.status === 200) {
                const { users } = response.data;
                const rows = users.map((x) => {
                    return {
                        Name: `${x.first_name} ${x.last_name}`,
                        Email: x.email,
                        Role: (
                            <Typography style={{backgroundColor: '#D3D3D3', borderRadius: '4px', fontSize: '12px', padding: '4px'}} >{x.role}</Typography>
                        ),
                        DeleteAction: (
                            <IconButton size="small" onClick={handleDelete}>
                                <Delete/>
                            </IconButton>
                        )
                    }
                });
                setRows(rows);
            }

        } catch (error) {
            console.error('Failed to fetch Users data', error);
        }
    }

    React.useEffect(() => {
        loadInfo();
    }, [])

    return (
        <div>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
            <Container maxWidth='md'>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}>
                    <PrimaryButton variant="contained" color="primary" onClick={handleOpen}>
                        Invite user
                    </PrimaryButton>
                </div>
                <TableComponent headers={headers} rows={rows}/>
            </Container>
        </div>
    );
}

export default UsersPage;