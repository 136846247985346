import React from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import AppForm from "../components/NewAppComponents/CreateAppForm";

const useStyles = createUseStyles((theme) => ({
    root: {
        flexGrow: 1,
        flexDirection: 'column', // Stack children vertically
        minHeight: '100vh', // Minimum height for vertical centering
        alignItems: 'center', // Center children horizontally
        justifyContent: 'center', // Center children vertically
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        transition: 'transform 0.2s',
    },
    imageContainer: {
        height: 140,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    },
    selected: {
        transform: 'scale(0.95)',
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: 'solid',
    },
    title: {
        textAlign: 'center',
        margin: '20px 0',
    }
}));

function CreateApp() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.title}>Deploy a New App</Typography>
            <Container fixed>
                <AppForm />
            </Container>
        </div>
    );
}

export default CreateApp;
