import React from "react";
import { Handle, Position } from "@xyflow/react";
import { BaseNode } from "./Base";

import "./Style.scss";

const handleNodeClick = (props) => {
    const { data, id } = props;
    data.onNodeClickCallback(id);
};

export const Source = (props) => (
    <div className="NodeWrapper">
        <BaseNode
            {...props}
            onNodeClick={() => handleNodeClick(props)}
        />
        <Handle type="source" position={Position.Right} id="a" />
    </div>
);

export const Action = (props) => (
    <div className="NodeWrapper">
        <Handle type="target" position={Position.Top} id="a" />
        <Handle type="target" position={Position.Left} id="b" />
        <BaseNode
            {...props}
            onNodeClick={() => handleNodeClick(props)}
        />
        <Handle type="source" position={Position.Right} id="a" />
        <Handle type="source" position={Position.Bottom} id="b" />
    </div>
);

export const ActionELK = (props) => {
    const { data } = props;

    return (
        <div className="NodeWrapper">
            {
                data?.targetHandles?.map((handle) => (
                    <Handle key={handle.id} id={handle.id} type="target" position={Position.Left} />
                ))
            }
            <BaseNode
                {...props}
                onNodeClick={() => handleNodeClick(props)}
            />
            {
                data?.sourceHandles?.map((handle) => (
                    <Handle key={handle.id} id={handle.id} type="source" position={Position.Right} />
                ))
            }
        </div>
    );
}

export const End = (props) => (
    <div className="NodeWrapper">
        <Handle type="target" position={Position.Left} />
        <BaseNode {...props} disabled={true} />
    </div>
);