import React from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    }
}));

function AccountPage({title}) {
    const classes = useStyles();

    return (
        <div>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
                <Container fixed>
            </Container>
        </div>
    );
}

export default AccountPage;