import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';

export const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        loading: true,
    });
    const [user, setUser] = useState({});
    /** Temporary data */
    const [currentWorkspace, setCurrentWorkspace] = useState("dbfd3ff9-fba1-4ac1-9249-9a4b8d60c32e");

    const getCurrentWorkspace = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_ACCOUNTS_URL}/accounts`, {
                params: {
                    id: user.id
                },
                withCredentials: true
            });
            
            const { last_used_workspace_id } = response.data;

            if (last_used_workspace_id) {
                setCurrentWorkspace(last_used_workspace_id);
            }
        } catch (error) {
            console.error('Failed to get last workspace', error);
        }
    };

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const resp = await axios.get(
                    `${process.env.REACT_APP_ACCOUNTS_URL}/auth/logged-in-check`,
                    {withCredentials: true}
                );

                setAuth({isAuthenticated: true, loading: false});

                if (resp.data.user_id) {
                    setUser(prevUser => ({
                        ...prevUser,
                        id: resp.data.user_id,
                    }));
                }
            } catch (error) {
                setAuth({isAuthenticated: false, loading: false});
            }
        };

        checkAuth();
    }, []);

    useEffect(() => {
        getCurrentWorkspace();
    }, [user])

    const login = () => {
        setAuth({isAuthenticated: true});
    };

    const logout = async () => {
        await axios.post(`${process.env.REACT_APP_ACCOUNTS_URL}/auth/signout`, {}, {withCredentials: true});
        setAuth({isAuthenticated: false});
    };

    const updateUser = (user) => {
        if (user && user.id) {
            setUser(user);
        }
    };

    return (
        <AuthContext.Provider value={{auth, user, updateUser, login, logout, currentWorkspace, setCurrentWorkspace}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
