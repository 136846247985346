import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ size, color, message }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <CircularProgress size={size} color={color} />
      {message && (
        <Typography variant="body1" style={{ marginTop: 16 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  message: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  size: 40,
  color: 'primary',
  message: '',
};

export default LoadingSpinner;
