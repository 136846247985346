import React from 'react';
import {Container} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {createUseStyles} from 'react-jss';
import {
    addEdge,
    useNodesState,
    useEdgesState,
    ControlButton,
    ReactFlowProvider
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import VirtualizedTable, {TableRowsType} from '../components/NewAppComponents/VirtualizedTable';
import {nodeTypes} from '../components/Nodes';
import {CustomTabPanel, StyledTabs} from '../components/NewAppComponents/CustomTabs';
import ReactDiffViewer from 'react-diff-viewer';
import IconButton from '@mui/material/IconButton';
import CropFreeIcon from '@mui/icons-material/CropFree';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CachedIcon from '@mui/icons-material/Cached';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Slide from '@mui/material/Slide';
import {Grid} from '@mui/material';
import {convertToLocalTimestamp} from "../utils/Date";
import {fetchInfrastructureEventItem, fetchInfrastructureEvents, fetchInfrastructureRelationships} from "../api/infrastructure";
import ReactMarkdown from 'react-markdown';
import { Tooltip } from 'react-tooltip'
import CopyToClopboard from 'copy-to-clipboard';
import {AuthContext} from '../context/AuthContext';
import ELK from 'elkjs/lib/elk.bundled.js';
import RF from '../components/Shared/ReactFlow';
import CloudIcons, { AWSAssets } from '../components/Shared/Icons'

const elk = new ELK();

const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': '20',
    'elk.spacing.nodeNode': '20',
    'elk.layered.nodePlacement.strategy': 'SIMPLE'
};

const getLayoutedElements = async (nodes, edges, options = {}) => {
    const graph = {
      id: 'root',
      layoutOptions: options,
      children: nodes.map((node) => {
        const targetPorts = node.data.targetHandles.map((t) => ({
            id: t.id,
            properties: {
              side: 'WEST',
            },
        }));
        const sourcePorts = node.data.sourceHandles.map((s) => ({
            id: s.id,
            properties: {
              side: 'EAST',
            },
        }));
        return {
            id: node.id,
            width: node.width ?? 150,
            height: node.height ?? 50,
            properties: {
              'org.eclipse.elk.portConstraints': 'FIXED_ORDER',
            },
            ports: [{ id: node.id }, ...targetPorts, ...sourcePorts],
        };
      }),
      edges: edges.map((e) => ({
        id: e.id,
        sources: [e.sourceHandle || e.source],
        targets: [e.targetHandle || e.target],
      })),
    };
  
    const layoutedGraph = await elk.layout(graph);

    const layoutedNodes = nodes.map((node) => {
        const layoutedNode = layoutedGraph.children?.find(
            (lgNode) => lgNode.id === node.id,
        );

        return {
            ...node,
            position: {
                x: layoutedNode?.x ?? 0,
                y: layoutedNode?.y ?? 0,
            },
        };
    });

    return { nodes: layoutedNodes, edges };
};

const useStyles = createUseStyles((theme) => ({
    container: {
        padding: '48px'
    },
    title: {
        backgroundColor: '#000',
        color: '#fff',
        padding: '8px 12px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        display: 'flex',
        '& span': {
            flex: 1
        }
    },
    titleRefresh: { color: '#fff', padding: 0 },
    diagramContainer: {padding: '16px', backgroundColor: '#fff'},
    diagramSubContainer: {border: '1px solid #000', height: '250px', borderRadius: '8px'},
    dialogTitle: {
        padding: '8px',
        backgroundColor: '#01BFBF',
        fontSize: '20px',
        fontWeight: '600',
        color: '#fff',
        borderRadius: '8px',
        flex: 1
    },
    tabTitleContainer: {
        color: '#fff !important',
        fontSize: '20px !important',
        textTransform: 'none',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16
    },
    tabContentTitle: {fontWeight: 'bold'},
    tabContentImportant: {color: '#05B6B7', fontWeight: '600'},
    tabDetailContainer: {borderRadius: '8px', border: '1px solid #000', width: '240px'},
    tabDetailTitleContainer: {
        backgroundColor: '#000',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
        textAlign: 'center',
        padding: '4px 36px',
        fontSize: '20px'
    },
    tabDetailContentGroup: {display: 'flex', gap: '4', margin: '8px 0px', alignItems: 'center'},
    tabDetailContentGroupField: {flex: 1, fontWeight: '600'},
    tabDetailContentGroupValue: {flex: 1, textAlign: 'center', padding: '4px 8px'},
    tabDetailContentGroupValueIds: {
        flex: 1, 
        textAlign: 'center', 
        textOverflow: 'ellipsis', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap', 
        cursor: 'pointer', 
        '&:hover': { backgroundColor: '#00000010' }, 
        '&:active': { backgroundColor: '#00000020' },
        padding: '4px 8px',
        userSelect: 'none'
    },
    tabRequestContentGroupTitle: {borderBottom: '1px solid #000', fontWeight: '700', fontSize: '16px'},
    tabRequestContentGroupField: {fontWeight: '600'},
    tabRequestContentGroupValue: { fontSize: 12, padding: '4px 8px',},
    tabRequestContentGroupCopy: { 
        fontSize: 12, 
        padding: '4px 8px',
        pointerEvents: 'auto',
        cursor: 'pointer', 
        '&:hover': { backgroundColor: '#00000010' }, 
        '&:active': { backgroundColor: '#00000020' },
        textOverflow: 'ellipsis', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap', 
        userSelect: 'none',
    },
    tabRequestContentGroupGrid: {
        pointerEvents: 'none'
    }
}));

const diffStyles = {
    diffContainer: {
        width: '100%',
    },
    line: {
        padding: '8px 10px',
        lineHeight: '20px',
        fontFamily: 'monospace',
        fontSize: '12px',
    },
    contentText: {
        width: 'calc(100%-50px)',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        paddingLeft: '10px',
        wordBreak: 'break-all'
    }
};

const columns = [
    {
        width: 100,
        label: 'ID',
        dataKey: 'id',
        align: 'center'
    },
    {
        label: 'Title',
        dataKey: 'title',
    },
    {
        width: 200,
        label: 'Description',
        dataKey: 'description'
    },
    {
        width: 100,
        label: 'Service',
        dataKey: 'service',
        align: 'center'
    },
    {
        label: 'Created',
        dataKey: 'created',
        align: 'center'
    },
    {
        width: 80,
        label: 'Impact',
        dataKey: 'impact',
        align: 'center'
    },
    {
        width: 140,
        label: 'Severity',
        dataKey: 'severity',
        align: 'center'
    }
];

const SeverityColors = {
    ["critical"]: '#FF0000',
    ["warning"]: '#FF9900',
    ["none"]: '#C2C2C2'
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VirtualizedTableMemo = React.memo(VirtualizedTable, (prevProps, nextProps) => {
    return nextProps.data === prevProps.data;
});

const InfrastructureDiagram = React.forwardRef(function InfrastructureDiagram(props, ref) {
    const diagramRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
        fitView() {
            diagramRef.current.fitView()
        }
    }));

    return (
        <ReactFlowProvider>
            <RF.Custom ref={diagramRef} {...props}>
                {props.children}
            </RF.Custom>
        </ReactFlowProvider>
    )
});

function InfrastructurePage({title}) {
    const classes = useStyles();
    const { user } = React.useContext(AuthContext);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [eventDetail, setEventDetail] = React.useState({});
    const [showDetail, setShowDetail] = React.useState(false);
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [fullCodeView, setFullCodeView] = React.useState(false);
    const [fullDiagram, setFullDiagram] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    const copyText = (e) => {
        CopyToClopboard(e.target.innerHTML);
    }

    const loadInitialData = async () => {
        try {
            const [responseInfraEvents, responseInfraRelationships] = await Promise.allSettled([
                fetchInfrastructureEvents(),
                fetchInfrastructureRelationships(user.id)
            ]);

            const _responseInfraEvents = responseInfraEvents.status === 'fulfilled' ? responseInfraEvents.value : {};
            const _responseInfraRelationships = responseInfraRelationships.status === 'fulfilled' ? responseInfraRelationships.value :  {};

            if (_responseInfraEvents.status === 200) {
                let events = _responseInfraEvents.data.events.map(event => {
                    let AwsServiceIcon;
                    let icon;

                    try {
                        AwsServiceIcon = require(`react-aws-icons/dist/aws/logo/${event.service}`).default;
                        icon = (
                            <>
                                <AwsServiceIcon/>
                                <br/>
                                <span style={{fontSize: '12px'}}>{event.service}</span>
                            </>
                        );
                    } catch (error) {
                        console.error(`No icon found for service: ${event.service}`);
                    }

                    return {
                        id: event.id,
                        title: event.title,
                        description: event.type,
                        service: icon,
                        created: convertToLocalTimestamp(event.created_at),
                        impact: <TableRowsType.Impact level={event.impact}/>,
                        severity: <TableRowsType.Severity severity={event.severity}/>,
                    };
                });

                setRows(events);
            }

            if (_responseInfraRelationships.status === 200) {

                const _edges = _responseInfraRelationships.data.edges?.map((x, i) => {
                    return {
                        id: `e${x.sourceId}-${x.targetId}`,
                        source: x.sourceId,
                        target: x.targetId,
                        sourceHandle: `${x.sourceId}-s-a`,
                        targetHandle: `${x.targetId}-t-a`,
                        type: 'straight'
                    }
                })

                const _nodes = _responseInfraRelationships.data.nodes?.map((item, i) => {
                    const iconPath = CloudIcons['aws']['resources'][item.resourceType.replace(' ', '')] || CloudIcons['aws']['default'];
                    const icon = AWSAssets(`./${iconPath}.js`).default
                    return {
                        id: item.id,
                        type: 'action-elk',
                        position: { x: 0 , y: 0 },
                        data: { 
                            properties: item.properties, 
                            resourceType: item.resourceType,
                            description: item.name || item.id,
                            icon,

                            label: item.name || item.resourceType,
                            targetHandles: _edges.filter((x) => x.target === item.id).map((y) => ({ id: y.targetHandle })),
                            sourceHandles: _edges.filter((x) => x.source === item.id).map((y) => ({ id: y.sourceHandle })),
                        }
                    }
                });

                onLayout({direction: 'RIGHT', ns: _nodes, es: _edges})
            }
        } catch (error) {
            console.error('Failed to fetch initial data', error);
        }
    }

    const onConnect = React.useCallback(
        (params) => setEdges((eds) => addEdge(params, eds)),
        [],
    );

    const onLayout = React.useCallback(
        ({ direction, ns, es }) => {
          const opts = { 'elk.direction': direction, ...elkOptions };

          getLayoutedElements(ns, es, opts).then(
            ({ nodes: layoutedNodes, edges: layoutedEdges }) => {
                setNodes(layoutedNodes);
                setEdges(layoutedEdges);
            },
          );
        },
        [nodes, edges],
    );

    React.useEffect(() => {
       loadInitialData();
    }, [])

    const handleClose = () => setShowDetail(false);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleClick = (item, index) => {
        getInfrastructureEventItem(item);
    };

    const getInfrastructureEventItem = React.useCallback(async (item) => {
        try {
            const response = await fetchInfrastructureEventItem(item.id)
            if (response.status === 200) {
                let eventItem = response.data

                let diffs = eventItem.diffs.map(diff => ({
                    resourceType: diff.resource_type,
                    changeType: diff.change_type,
                    before: diff.before,
                    after: diff.after
                }));

                let detail = {
                    id: eventItem.request.event_detail.id,
                    overview: {
                        title: eventItem.overview.title,
                        description: eventItem.overview.description,
                    },
                    diffs: diffs,
                    request: {
                        id: eventItem.request.event_detail.id,
                        resourceName: eventItem.request.event_detail.resource_name,
                        accountId: eventItem.request.event_detail.account_id,
                        eventTime: eventItem.request.event_detail.event_time,
                        service: eventItem.request.event_detail.service,
                        resourceId: eventItem.request.event_detail.resource_id,
                        resourceType: eventItem.request.event_detail.resource_type,
                        resourceStatus: eventItem.request.event_detail.resource_status,
                    },
                    details: {
                        severity: eventItem.details.severity,
                        resourceName: eventItem.details.resource_name,
                        accountId: eventItem.details.account_id,
                        eventTime: eventItem.details.event_time,
                        service: eventItem.details.service,
                        resourceId: eventItem.details.resource_id,
                        resourceType: eventItem.details.resource_type,
                        resourceStatus: eventItem.details.resource_status,
                    },
                }
                setEventDetail(detail);
                setShowDetail(true);
                setSelectedTab(0);
            }
        } catch (error) {
            console.error('Failed to fetch infrastructure event item', error);
        }
    });

    return (
        <div className={classes.container}>
            <Container fixed>
                <div className={classes.title}>
                    <span>Infrastructure Architecture</span>
                    <IconButton type="submit" className={classes.titleRefresh} onClick={loadInitialData}>
                        <CachedIcon/>
                    </IconButton>
                </div>
                <div className={classes.diagramContainer}>
                    <div className={classes.diagramSubContainer}>
                        <InfrastructureDiagram
                            id='react-flow-view'
                            nodes={nodes}
                            nodeTypes={nodeTypes}
                            onNodesChange={onNodesChange}
                            edges={edges}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            fitView
                        >
                            <ControlButton onClick={() => setFullDiagram(true)} data-tooltip-id='enlarge' data-tooltip-content={'enlarge'}>
                                <OpenInNewIcon/>
                            </ControlButton>
                            <Tooltip id='enlarge'/>
                        </InfrastructureDiagram>
                    </div>
                </div>
                <div style={{height: "36px"}}/>
                <VirtualizedTableMemo columns={columns} data={rows} sx={{height: 700}} tableCellStyle={{lineHeight: '24px'}}
                                rowContentStyle={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    cursor: 'pointer'
                                }} hover onClick={handleClick}/>
            </Container>
            <Dialog
                open={showDetail}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        handleClose();
                    },
                }}
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle sx={{display: 'flex', gap: 4}}>
                    <span className={classes.dialogTitle}>
                        {eventDetail.overview?.title}
                    </span>
                    <IconButton type="submit">
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{display: 'flex', gap: '20px'}}>
                    <Box sx={{flex: 1, minWidth: '600px'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <StyledTabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                                <Tab className={classes.tabTitleContainer}
                                    sx={{background: selectedTab === 0 ? '#05B6B7' : '#000'}}
                                    label="Overview" {...a11yProps(0)} />
                                <Tab className={classes.tabTitleContainer}
                                    sx={{background: selectedTab === 1 ? '#05B6B7' : '#000'}}
                                    label="Diff" {...a11yProps(1)} />
                                <Tab className={classes.tabTitleContainer}
                                    sx={{background: selectedTab === 2 ? '#05B6B7' : '#000'}}
                                    label="Request" {...a11yProps(2)} />
                            </StyledTabs>
                        </Box>
                        <CustomTabPanel value={selectedTab} index={0}>
                            <ReactMarkdown>{eventDetail.overview?.description}</ReactMarkdown>
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={1}>
                            <Box position={'relative'}>
                                <Box overflow={'scroll'} maxHeight={'400px'}>
                                    <div>
                                        {eventDetail.diffs?.map((diff, index) => (
                                            <div key={index} style={{marginBottom: '20px', width: '100%'}}>
                                                <h4 style={{textAlign: 'center'}}>[{diff.changeType}] {diff.resourceType}</h4>
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <ReactDiffViewer
                                                        oldValue={diff.before}
                                                        newValue={diff.after}
                                                        splitView={true}
                                                        styles={diffStyles}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Box>
                                <Box position={'absolute'} bottom={0} right={0}>
                                    <IconButton aria-label="delete" onClick={() => setFullCodeView(true)}>
                                        <CropFreeIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={2}>
                            <Box>
                                <Box marginBottom={'16px'}><span className={classes.tabRequestContentGroupTitle}>Event Detail</span></Box>
                                <Grid container rowSpacing={6} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Event ID</span>
                                    </Grid>
                                    <Grid item xs={7} className={classes.tabRequestContentGroupGrid}>
                                        <span data-tooltip-id="request-id" data-tooltip-content={eventDetail.request?.id} onClick={copyText}
                                            className={classes.tabRequestContentGroupCopy}>{eventDetail.request?.id}</span>
                                        <Tooltip id="request-id"/>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={6} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Resource Name</span>
                                    </Grid>
                                    <Grid item xs={5} className={classes.tabRequestContentGroupGrid}>
                                        <span data-tooltip-id="request-resourceName" data-tooltip-content={eventDetail.request?.resourceName} onClick={copyText}
                                            className={classes.tabRequestContentGroupCopy}>{eventDetail.request?.resourceName}</span>
                                        <Tooltip id="request-resourceName"/>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={6} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Account ID</span>
                                    </Grid>
                                    <Grid item xs={5} className={classes.tabRequestContentGroupGrid}>
                                        <span data-tooltip-id="request-accountId" data-tooltip-content={eventDetail.request?.accountId} onClick={copyText}
                                            className={classes.tabRequestContentGroupCopy}>{eventDetail.request?.accountId}</span>
                                        <Tooltip id="request-accountId"/>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={8} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Event Time</span>
                                    </Grid>
                                    <Grid item xs={7} className={classes.tabRequestContentGroupGrid}>
                                        <span
                                            className={classes.tabRequestContentGroupValue}>{convertToLocalTimestamp(eventDetail.request?.eventTime)}</span>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={6} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Service</span>
                                    </Grid>
                                    <Grid item xs={5} className={classes.tabRequestContentGroupGrid}>
                                        <span
                                            className={classes.tabRequestContentGroupValue}>{eventDetail.request?.service}</span>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={6} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Resource ID</span>
                                    </Grid>
                                    <Grid item xs={5} className={classes.tabRequestContentGroupGrid}>
                                        <span data-tooltip-id="request-resourceId" data-tooltip-content={eventDetail.request?.resourceId} onClick={copyText}
                                            className={classes.tabRequestContentGroupCopy}>{eventDetail.request?.resourceId}</span>
                                        <Tooltip id="request-resourceId"/>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={6} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Resource Type</span>
                                    </Grid>
                                    <Grid item xs={5} className={classes.tabRequestContentGroupGrid}>
                                        <span
                                            className={classes.tabRequestContentGroupValue}>{eventDetail.request?.resourceType}</span>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={6} spacing={2} flex={1} marginLeft={0} fontSize={'16px'}>
                                    <Grid item xs={5}>
                                        <span className={classes.tabRequestContentGroupField}>Resource Status</span>
                                    </Grid>
                                    <Grid item xs={5} className={classes.tabRequestContentGroupGrid}>
                                        <span
                                            className={classes.tabRequestContentGroupValue}>{eventDetail.request?.resourceStatus}</span>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                    </Box>
                    <Box className={classes.tabDetailContainer}>
                        <Box className={classes.tabDetailTitleContainer}>
                            <span style={{color: '#fff'}}>Details</span>
                        </Box>
                        <Box sx={{padding: '8px', fontSize: '12px'}}>
                            <Box className={classes.tabDetailContentGroup}>
                                <span className={classes.tabDetailContentGroupField}>Severity</span>
                                <span className={classes.tabDetailContentGroupValue} style={{
                                    backgroundColor: SeverityColors[eventDetail.details?.severity.toLowerCase()],
                                    color: '#fff',
                                    padding: '4px',
                                    fontWeight: '600',
                                    borderRadius: '4px'
                                }}>{eventDetail.details?.severity}</span>
                            </Box>
                            <Box className={classes.tabDetailContentGroup}>
                                <span className={classes.tabDetailContentGroupField}>Resource ID</span>
                                <span data-tooltip-id="details-resourceId" data-tooltip-content={eventDetail.details?.resourceId} onClick={copyText}
                                    className={classes.tabDetailContentGroupValueIds}>{eventDetail.details?.resourceId}</span>
                                <Tooltip id="details-resourceId"/>
                            </Box>
                            <Box className={classes.tabDetailContentGroup}>
                                <span className={classes.tabDetailContentGroupField}>Account ID</span>
                                <span data-tooltip-id="details-accountId" data-tooltip-content={eventDetail.details?.accountId} onClick={copyText}
                                    className={classes.tabDetailContentGroupValueIds}>{eventDetail.details?.accountId}</span>
                                <Tooltip id="details-accountId"/>
                            </Box>
                            <Box className={classes.tabDetailContentGroup}>
                                <span className={classes.tabDetailContentGroupField}>Service</span>
                                <span
                                    className={classes.tabDetailContentGroupValue}>{eventDetail.details?.service}</span>
                            </Box>
                            <Box className={classes.tabDetailContentGroup}>
                                <span className={classes.tabDetailContentGroupField}>Resource Type</span>
                                <span
                                    className={classes.tabDetailContentGroupValue}>{eventDetail.details?.resourceType}</span>
                            </Box>
                            <Box className={classes.tabDetailContentGroup}>
                                <span className={classes.tabDetailContentGroupField}>Resource Status</span>
                                <span
                                    className={classes.tabDetailContentGroupValue}>{eventDetail.details?.resourceStatus}</span>
                            </Box>
                            <Box className={classes.tabDetailContentGroup}>
                                <span className={classes.tabDetailContentGroupField}>Event Time</span>
                                <span className={classes.tabDetailContentGroupValue}>{convertToLocalTimestamp(eventDetail.details?.eventTime)}</span>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen
                open={fullCodeView}
                onClose={() => setFullCodeView(false)}
                TransitionComponent={Transition}
            >
                <AppBar sx={{position: 'relative', backgroundColor: '#01BFBF'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setFullCodeView(false)}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div>
                    {eventDetail.diffs?.map((diff, index) => (
                        <div key={index} style={{marginBottom: '20px', width: '100%'}}>
                            <h4 style={{textAlign: 'left'}}>[{diff.changeType}] {diff.resourceType}</h4>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <ReactDiffViewer
                                    oldValue={diff.before}
                                    newValue={diff.after}
                                    splitView={true}
                                    styles={diffStyles}

                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Dialog>
            <Dialog
                open={fullDiagram}
                onClose={() => {
                    setFullDiagram(false);
                }}
                TransitionComponent={Transition}
                fullWidth
                maxWidth={'lg'}
            >
                <React.Fragment>
                    <Box display={'flex'} marginLeft={'4px'} justifyContent={'flex-end'}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setFullDiagram(false)}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                    <Box height={window.screen.height} marginBottom={'12px'} marginRight={'12px'} marginLeft={'12px'} border={'1px solid #000'} borderRadius={'4px'}>
                        <InfrastructureDiagram
                            id='react-flow-full-view'
                            nodes={nodes}
                            nodeTypes={nodeTypes}
                            onNodesChange={onNodesChange}
                            edges={edges}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            fitView
                        >
                            <ControlButton onClick={() => setFullDiagram(false)} data-tooltip-id='exit-enlarge' data-tooltip-content={'exit'}>
                                <ExitToAppIcon/>
                            </ControlButton>
                            <Tooltip id='exit-enlarge'/>
                        </InfrastructureDiagram>
                    </Box>
                </React.Fragment>
            </Dialog>
        </div>
    );
}

export default InfrastructurePage;