import React from 'react';
import { Outlet } from 'react-router-dom';

function Settings({title}) {

    return (
        <Outlet/>
    );
}

export default Settings;

export { RolesPage } from './RolesPage';
export { SecretsPage } from './SecretsPage';
export { UsersPage } from './UsersPage';
export { WorkspacePage } from './WorkspacePage';
export { IntegrationsPage } from './IntegrationsPage';
export { default as SwitchWorkspaceModal } from './SwitchWorkspaceModal';