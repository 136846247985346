import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

function PullRequestsTable({pullRequests}) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">PR Title</TableCell>
                        <TableCell align="center">Created At</TableCell>
                        <TableCell align="center">User</TableCell>
                        <TableCell align="center">Link</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pullRequests.map((pr) => (
                        <TableRow key={pr.id}>
                            <TableCell component="th" scope="row" align="center">
                                {pr.title}
                            </TableCell>
                            <TableCell align="center">{new Date(pr.created_at).toLocaleDateString()}</TableCell>
                            <TableCell align="center">{pr.user.login}</TableCell>
                            <TableCell align="center">
                                <Button variant="contained" color="primary" onClick={() => window.open(pr.html_url, '_blank')}>
                                    View
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PullRequestsTable;
