import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#000'),
    backgroundColor: '#F2B8B8',
    '&:hover': {
      backgroundColor: '#F2B8B8'
    },
}));

export default CancelButton;