import React, {useContext, useEffect, useState} from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import Button from "@mui/material/Button";
import TableComponent from "../components/Shared/TableComponent";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ActivityIndicator from "../components/Shared/ActivityIndicator";
import {convertToLocalTimestamp} from "../utils/Date";
import AddIcon from '@mui/icons-material/Add';
import {useLocation, useNavigate} from 'react-router-dom';
import {AuthContext} from '../context/AuthContext';
import AddIntegrationModal from "../components/IntegrationsComponents/AddIntegrationModal";
import Banner from "../components/Shared/Banner";
import {fetchGithubInstallations} from "../api/integrations";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0'
    }
}));

const headers = ["ID", "Name", "Owner", "Created", "Last Update", "Status", "View"]

function IntegrationsPage({title}) {
    const navigate = useNavigate();
    const location = useLocation();
    const {user} = useContext(AuthContext);
    const classes = useStyles();
    const [error, setError] = useState('');
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [showBanner, setShowBanner] = useState(!!location.state?.message);

    const handleIntegrationClick = (user_id, installation_id) => {
        navigate('/integrations/github', {state: {user_id: user_id, installation_id: installation_id}});
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const getInstallations = async () => {
            try {
                const response = await fetchGithubInstallations(user.id)
                if (response.status === 200) {
                    setRows((prevItems) => [...prevItems, {
                        id: response.data.id,
                        name: "Github",
                        owner: response.data.target_name,
                        created: convertToLocalTimestamp(response.data.created_at),
                        lastUpdate: convertToLocalTimestamp(response.data.updated_at),
                        active: <ActivityIndicator status={response.data.is_active ? "active" : "inactive"}/>,
                        view: (
                            <Button variant="contained" color="primary" size="small"
                                    onClick={() => handleIntegrationClick(response.data.user_id, response.data.id)}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Button>
                        )
                    }]);
                }
            } catch (error) {
                setError('Failed to fetch Github Integrations.');
                console.error('Failed to fetch Github Integrations', error);
            }
        }

        getInstallations();
    }, [])

    const handleBannerClose = () => {
        setShowBanner(false);
    };

    return (
        <div>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
            <Container fixed>
                {showBanner && (
                    <Banner
                        message={location.state?.message}
                        severity="success"
                        onClose={handleBannerClose}
                    />
                )}
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
                        <AddIcon/>
                    </Button>
                    <AddIntegrationModal open={open} handleClose={handleClose}/>
                </div>
                <TableComponent headers={headers} rows={rows}/>
            </Container>
        </div>
    );
}

export default IntegrationsPage;