import React from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {
    ReactFlow,
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { nodeTypes } from './../components/Nodes';

const saInitialNodes = [
    {
      id: '1',
      type: 'source',
      position: { x: 0, y: 0 },
      data: {
        description: 'web',
        icon: 'reactjs'
      }
    },
    {
      id: '2',
      type: 'action',
      position: { x: 150, y: 0 },
      data: {
        description: 'graph',
        icon: 'graphql'
      }
    },
    {
      id: '3',
      type: 'action',
      position: { x: 350, y: -100 },
      data: {
        description: 'user',
        icon: 'golang'
      }
    },
    {
      id: '4',
      type: 'action',
      position: { x: 350, y: -30 },
      data: {
        description: 'auth',
        icon: 'golang'
      }
    },
    {
      id: '5',
      type: 'action',
      position: { x: 350, y: 30 },
      data: {
        description: 'accounts',
        icon: 'golang'
      }
    },
    {
      id: '6',
      type: 'action',
      position: { x: 350, y: 100 },
      data: {
        description: 'transactions',
        icon: 'golang'
      }
    },
    {
      id: '7',
      type: 'action',
      position: { x: 550, y: -100 },
      data: {
        description: 'auth0 api',
        icon: 'oauth'
      }
    },
    {
      id: '8',
      type: 'action',
      position: { x: 550, y: -30 },
      data: {
        description: 'postgres',
        icon: 'postgresql'
      }
    },
    {
      id: '9',
      type: 'action',
      position: { x: 550, y: 60 },
      data: {
        description: 'plaid api',
        icon: 'plaid'
      }
    },
];
    
const saInitialEdges = [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e3-4',
      source: '3',
      target: '4',
      sourceHandle: 'b',
      targetHandle: 'a',
      type: 'straight'
    },
    {
      id: 'e2-5',
      source: '2',
      target: '5',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e2-6',
      source: '2',
      target: '6',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e3-7',
      source: '3',
      target: '7',
      sourceHandle: 'a',
      targetHandle: 'b',
      animated: true
    },
    {
      id: 'e3-8',
      source: '3',
      target: '8',
      sourceHandle: 'a',
      targetHandle: 'b',
      animated: true
    },
    {
      id: 'e4-8',
      source: '4',
      target: '8',
      sourceHandle: 'a',
      targetHandle: 'b',
      animated: true
    },
    {
      id: 'e5-8',
      source: '5',
      target: '8',
      sourceHandle: 'a',
      targetHandle: 'b',
      animated: true
    },
    {
      id: 'e6-8',
      source: '6',
      target: '8',
      sourceHandle: 'a',
      targetHandle: 'b',
      animated: true
    },
    {
      id: 'e5-9',
      source: '5',
      target: '9',
      sourceHandle: 'a',
      targetHandle: 'b',
      animated: true
    },
    {
      id: 'e6-9',
      source: '6',
      target: '9',
      sourceHandle: 'a',
      targetHandle: 'b',
      animated: true
    },
];
  
const iaInitialNodes = [
    {
      id: '1',
      type: 'source',
      position: { x: 0, y: 0 },
      data: {
        description: 'ELB',
        icon: 'aws/elb',
      }
    },
    {
      id: '2',
      type: 'action',
      position: { x: 150, y: 0 },
      data: {
        description: 'EC2',
        icon: 'aws/ec2'
      }
    },
    {
      id: '3',
      type: 'action',
      position: { x: 300, y: 0 },
      data: {
        description: 'EC2',
        icon: 'aws/ec2'
      }
    },
    {
      id: '4',
      type: 'action',
      position: { x: 450, y: -50 },
      data: {
        description: 'RDS',
        icon: 'aws/rds'
      }
    },
    {
      id: '5',
      type: 'action',
      position: { x: 450, y: 0 },
      data: {
        description: 'redis',
        icon: 'redis'
      }
    },
    {
      id: '6',
      type: 'action',
      position: { x: 450, y: 50 },
      data: {
        description: 'S3',
        icon: 'aws/s3'
      }
    },
];
    
const iaInitialEdges = [
    {
      id: 'e1-2',
      source: '1',
      target: '2',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e2-3',
      source: '2',
      target: '3',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e3-4',
      source: '3',
      target: '4',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e3-5',
      source: '3',
      target: '5',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    },
    {
      id: 'e3-6',
      source: '3',
      target: '6',
      sourceHandle: 'a',
      targetHandle: 'b',
      type: 'straight'
    }
];

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    }
}));

function Home({title}) {
    const classes = useStyles();
    const [saNodes, setSANodes] = React.useState(saInitialNodes);
    const [saEdges, setSAEdges] = React.useState(saInitialEdges);
    const [iaNodes, setIANodes] = React.useState(iaInitialNodes);
    const [iaEdges, setIAEdges] = React.useState(iaInitialEdges);

    const onSANodesChange = React.useCallback(
        (changes) => setSANodes((nds) => applyNodeChanges(changes, nds)),
        [],
    );

    const onSAEdgesChange = React.useCallback(
        (changes) => setSAEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );

    const onSAConnect = React.useCallback(
        (params) => setSAEdges((eds) => setSAEdges(params, eds)),
        [],
    );

    const onIANodesChange = React.useCallback(
      (changes) => setIANodes((nds) => applyNodeChanges(changes, nds)),
      [],
    );

    const onIAEdgesChange = React.useCallback(
        (changes) => setIAEdges((eds) => applyEdgeChanges(changes, eds)),
        [],
    );

    const onIAConnect = React.useCallback(
        (params) => setIAEdges((eds) => setIAEdges(params, eds)),
        [],
    );

    return (
        <div>
            <Typography variant="h5" className={classes.title}></Typography>
            <Container fixed>
              <div style={{backgroundColor: '#000', color: '#fff', padding: '8px 12px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}>
                <span>Service Architecture</span>
              </div>
              <div style={{ padding: '16px', backgroundColor: '#fff' }}>
                <div style={{border: '1px solid #000', height: '250px', borderRadius: '8px'}}>
                  <ReactFlow
                      nodes={saNodes}
                      nodeTypes={nodeTypes}
                      onNodesChange={onSANodesChange}
                      edges={saEdges}
                      onEdgesChange={onSAEdgesChange}
                      onConnect={onSAConnect}
                      fitView
                  >
                      <Background />
                      <Controls />
                  </ReactFlow>
                </div>
              </div>
              <div style={{margin: '24px'}}/>
              <div style={{backgroundColor: '#000', color: '#fff', padding: '8px 12px', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}>
                <span>Infrastructure Architecture</span>
              </div>
              <div style={{ padding: '16px', backgroundColor: '#fff' }}>
                <div style={{border: '1px solid #000', height: '250px', borderRadius: '8px'}}>
                  <ReactFlow
                      nodes={iaNodes}
                      nodeTypes={nodeTypes}
                      onNodesChange={onIANodesChange}
                      edges={iaEdges}
                      onEdgesChange={onIAEdgesChange}
                      onConnect={onIAConnect}
                      fitView
                  >
                      <Background />
                      <Controls />
                  </ReactFlow>
                </div>
              </div>
            </Container>
        </div>
    );
}

export default Home;