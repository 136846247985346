import React from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import TableComponent from "../../../components/Shared/TableComponent";
import { AuthContext } from "../../../context/AuthContext";
import { fetchRolesByWorkspaceId } from "../../../api/integrations";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    }
}));

const headers = ["Role", "Workspaces", "Users", "Roles", "Secrets", "Integrations"]

function RolesPage({title}) {
    const classes = useStyles();
    const { currentWorkspace } = React.useContext(AuthContext);
    const [rows, setRows] = React.useState([]);

    const callbackfnGetPermissions = (scope = {}) => {
        const keys = Object.keys(scope);
        const fn = (key) => {
            if (scope[key]) return key;
        }
        return keys.map(fn).join(' ').trimEnd();
    }

    const loadInfo = async () => {
        try {
            const response = await fetchRolesByWorkspaceId(currentWorkspace);
            
            if (response.status === 200) {
                const { roles } = response.data;
                const rows = roles.map((x) => {
                    return {
                        Role: x.name,
                        Workspaces: callbackfnGetPermissions(x.permissions?.workspaces),
                        Users: callbackfnGetPermissions(x.permissions?.users),
                        Roles: callbackfnGetPermissions(x.permissions?.roles),
                        Secrets: callbackfnGetPermissions(x.permissions?.secrets),
                        Integrations: callbackfnGetPermissions(x.permissions?.integrations),
                    }
                });
                setRows(rows);
            }

        } catch (error) {
            console.error('Failed to fetch roles data', error);
        }
    }

    React.useEffect(() => {
        loadInfo();
    }, [])

    return (
        <div>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
            <Container maxWidth='md'>
                <TableComponent headers={headers} rows={rows}/>
            </Container>
        </div>
    );
}

export default RolesPage;