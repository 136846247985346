import React, { Suspense } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Box from '@mui/material/Box';
import "./Style.scss";

export const BaseNode = ({
  type,
  data,
  selected,
  disabled,
  onNodeClick,
  additionalClassName,
}) => {
  const baseIconPath = `${process.env.PUBLIC_URL}/images/`;
  const ServiceImage = () => <LazyLoadImage src={`${baseIconPath}${data.icon}.png`} width={16} height={'auto'}/>

  const content = (
    <React.Fragment key={data.description}>
        <div className="NodeLeftIcon">
            <React.Fragment>
              <Box display={'flex'} flexDirection={'column'} gap={'4px'} padding={'8px'} alignItems={'center'}>
                { data.icon ? data.resourceType ? <data.icon/> : ServiceImage(): ServiceImage() }
                <span style={{fontSize: '6px', textAlign: 'center'}}>{data.resourceType}</span>
              </Box>
            </React.Fragment>
        </div>
        <div className="NodeContent">
            <div className="NodeTitle">{data.title}</div>
            <p className="NodeDesc">{data.description}</p>
        </div>
    </React.Fragment>
  );
  return (
    <div
      data-selected={selected}
      aria-disabled={disabled}
      className={`NodeInnerWrapper ${additionalClassName}`}
      {...(onNodeClick && { onClick: () => onNodeClick(type, data) })}
    >
      {content}
    </div>
  );
};