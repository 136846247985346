import axios from "axios";

export const GetPullRequests = async (user_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_GITHUB_URL}/pull-requests`, {
            params: {
                user_id
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching pull requests:", error);
        throw error
    }
}