import React from 'react';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    },
    cardContainer: { backgroundColor: '#000', borderRadius:  8 },
    cardTitle: { color: '#fff', textAlign: 'center', padding: '4px 8px', fontWeight: '600', fontSize: '20px' },
    cardBody: { display: 'flex', flexDirection: 'column', backgroundColor: '#fff', alignItems: 'center', gap: 8, padding: '12px'},
    cardBodyTitle: { fontSize: '88px', color: '#05B6B7', fontWeight: 'bold', lineHeight: '1' },
    cardBodySubTitle: { fontSize: '12px', color: '#CFCFCF', fontWeight: 'bold' }
}));

function SummaryCard({title, count, label}) {
    const classes = useStyles();

    return (
        <div className={classes.cardContainer}>
            <div className={classes.cardTitle}><span>{ title }</span></div>
            <div className={classes.cardBody}>
                <span className={classes.cardBodyTitle}>{ count }</span>
                <span className={classes.cardBodySubTitle}>{ label }</span>
            </div>
        </div>
    );
}

export default SummaryCard;