import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./components/Shared/Theme";
import AuthProvider from "./context/AuthContext";
import {GlobalProvider} from "./context/Provider";
import 'react-tooltip/dist/react-tooltip.css'
import { ReactFlowProvider } from '@xyflow/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <GlobalProvider>
            <AuthProvider>
                <BrowserRouter>
                {/* <ReactFlowProvider> */}
                    <App/>
                {/* </ReactFlowProvider> */}
                </BrowserRouter>
            </AuthProvider>
        </GlobalProvider>
    </ThemeProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
