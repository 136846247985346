import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function TableComponent({ headers, rows, rowsAsComponent = false }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers.map((header, index) => (
                            <TableCell key={index} align="center">{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {   rowsAsComponent ?
                        <React.Fragment>
                            { rows() } 
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {
                                rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {Object.values(row).map((cell, cellIndex) => (
                                            <TableCell key={cellIndex} align="center">
                                                {cell}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            }
                        </React.Fragment>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableComponent;