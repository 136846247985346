import React, {useEffect, useState} from 'react';
import {Container} from '@mui/material';
import {createUseStyles} from 'react-jss';
import {useLocation} from "react-router-dom";
import AppResources from "../components/AppComponents/AppResourcesTableComponent";
import {Collapse, Typography} from '@mui/material';
import AppDetails from "../components/AppComponents/AppDetailsComponent";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from "@mui/material/Button";
import PullRequestsTable from "../components/AppComponents/PullRequestsTable";
import AwsResourcesTable from "../components/AppComponents/AwsResourcesComponent";


const useStyles = createUseStyles((theme) => ({
    root: {
        flexGrow: 1,
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        transition: 'transform 0.2s',
    },
    imageContainer: {
        height: 140,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    },
    selected: {
        transform: 'scale(0.95)',
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: 'solid',
    },
    title: {
        textAlign: 'center',
        margin: '20px 0',
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'top',
        width: '100%',
    },
    detailsContainer: {
        flex: 1,
        padding: theme.spacing(2)
    },
    tableContainer: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2)
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2)
    }
}));

function ViewApp() {
    const classes = useStyles();
    const location = useLocation();
    const app = location.state?.app;
    const [isTableVisible, setIsTableVisible] = useState(true);
    const [awsEc2InstanceId, setAwsEc2InstanceId] = useState('');
    const [appPublicDns, setAppPublicDns] = useState('');
    const [appPublicDnsStatus, setAppPublicDnsStatus] = useState('');
    const [resources, setResources] = useState([]);
    const [awsResources, setAwsResources] = useState([]);
    const [pullRequests, setPullRequests] = useState([]);

    useEffect(() => {
        const fetchPullRequests = async () => {
            const organization = 'adaio';
            const repo = 'user';
            const url = `https://api.github.com/repos/${organization}/${repo}/pulls?state=open`;
            const token = 'sometoken';

            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `token ${token}`
                    }
                });
                const data = await response.json();

                setPullRequests(data);
            } catch (error) {
                console.error('Failed to fetch pull requests:', error);
            }
        };

        fetchPullRequests();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://localhost:8081/aws/resources', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AwsConfig: {
                            Region: 'us-west-1',
                            Key: 'key',
                            Secret: 'secret',
                            Token: 'token'
                        },
                        AppName: 'user'
                    })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setAwsResources(responseData.resources);

                for (const resource of responseData.resources) {
                    if (resource.type === 'instance') {
                        setAwsEc2InstanceId(resource.name)
                        break;
                    }
                }
            } catch (error) {
                console.error('Error fetching AWS resources:', error);
            }
        };


        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://localhost:8081/aws/ec2', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AwsConfig: {
                            Region: 'us-west-1',
                            Key: 'key',
                            Secret: 'secret',
                            Token: 'token'
                        },
                        InstanceId: awsEc2InstanceId
                    })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const responseData = await response.json();
                setAppPublicDns(`http://${responseData.PublicDns}`);
                setAppPublicDnsStatus(responseData.Status);
            } catch (error) {
                console.error('Error fetching AWS resources:', error);
            }
        };


        fetchData();
    }, [awsResources]);

    useEffect(() => {
        const res = [
            {
                name: "App Public DNS",
                status: appPublicDnsStatus,
                link: appPublicDns
            },
            {
                name: "Github Repository",
                status: "created",
                link: `https://github.com/adaio/${app.name}`
            },
            {
                name: "AWS Networking Resources",
                status: "operational",
                link: `https://us-west-1.console.aws.amazon.com/ec2/home?region=us-west-1#InstanceDetails:instanceId=${awsEc2InstanceId}`
            },
        ]
        setResources(res)
    }, [pullRequests, awsResources, appPublicDns]);

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible);
    };

    return (
        <div className={classes.root}>
            <Typography variant="h5" className={classes.title}>App Details</Typography>
            <Container fixed>
                <div className={classes.flexContainer}>
                    <div className={classes.detailsContainer}>
                        <AppDetails app={app}/>
                    </div>
                    <div className={classes.tableContainer}>
                        <Collapse in={isTableVisible}>
                            <AppResources resources={resources}/>
                        </Collapse>
                    </div>
                </div>
                <div className={classes.flexContainer}>
                </div>
                <div className={classes.tableContainer}>
                    <Typography variant="h5" className={classes.title}>Pull Requests</Typography>
                    <div className={classes.buttonContainer}>
                        <Button
                            endIcon={isTableVisible ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            onClick={toggleTableVisibility}
                        >
                            {isTableVisible ? 'Hide Pull Requests' : 'Show Pull Requests'}
                        </Button>
                    </div>
                    <Collapse in={isTableVisible}>
                        <PullRequestsTable pullRequests={pullRequests}/>
                    </Collapse>
                </div>
                <div className={classes.tableContainer}>
                    <Typography variant="h5" className={classes.title}>AWS Resources</Typography>
                    <div className={classes.buttonContainer}>
                        <Button
                            endIcon={isTableVisible ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            onClick={toggleTableVisibility}
                        >
                            {isTableVisible ? 'Hide AWS Resources' : 'Show AWS Resources'}
                        </Button>
                    </div>
                    <Collapse in={isTableVisible}>
                        <AwsResourcesTable resources={awsResources}/>
                    </Collapse>
                </div>
            </Container>
        </div>
    )
        ;
}

export default ViewApp;