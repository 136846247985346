import React, {useContext, useEffect} from 'react';
import {Container} from '@mui/material';
import {useNavigate, useSearchParams} from 'react-router-dom';
import axios from 'axios';
import {AuthContext} from '../context/AuthContext';
import LoadingSpinner from '../components/Shared/LoadingSpinner';

function GithubIntegrationOauthCallback() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {user} = useContext(AuthContext);

    useEffect(() => {
        const code = searchParams.get('code');
        const installationId = searchParams.get('installation_id');
        const setupAction = searchParams.get('setup_action');

        if (setupAction === 'install' && code && installationId) {
            axios
                .post(
                    `${process.env.REACT_APP_GITHUB_URL}/oauth/callback`,
                    {
                        user_id: parseInt(user.id, 10),
                        code: code,
                        installation_id: installationId,
                    },
                    {withCredentials: true}
                )
                .then((response) => {
                    navigate('/integrations', {state: {message: 'Success adding Github integration!'}})
                })
                .catch((error) => {
                    navigate('/integrations', {
                        state: {message: 'Failed to add Github integration! Contact our support team for additional help.',}
                    })
                    console.error(error)
                });
        }
    }, [navigate, searchParams, user.id]);

    return (
        <Container fixed>
            <LoadingSpinner size={50} color="primary" message="Loading..."/>
        </Container>
    );

}

export default GithubIntegrationOauthCallback;
