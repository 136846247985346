import React, { useEffect } from 'react';
import {Container, Grid} from '@mui/material';
import {createUseStyles} from 'react-jss';
import SummaryCard from '../components/NewAppComponents/SummaryCard';
import { AuthContext } from "../context/AuthContext";
import { GetPullRequests } from '../api/pull-requests';
import VirtualizedTable, { TableRowsType } from '../components/NewAppComponents/VirtualizedTable';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { localeSettings } from '../utils/Constants';

const useStyles = createUseStyles((theme) => ({
    container: {
        padding: '48px'
    }
}));

const columns = [
    {
      width: 100,
      label: 'ID',
      dataKey: 'id',
      align: 'center'
    },
    {
      label: 'Title',
      dataKey: 'title',
    },
    {
      width: 100,
      label: 'Author',
      dataKey: 'author',
      align: 'center'
    },
    {
      width: 100,
      label: 'Repo',
      dataKey: 'repo'
    },
    {
      width: 160,
      label: 'Created',
      dataKey: 'created',
      align: 'center'
    },
    {
      width: 140,
      label: 'Open\u000AComments',
      dataKey: 'open_comments',
      align: 'center'
    },
    {
      width: 140,
      label: 'Status',
      dataKey: 'status',
      align: 'center'
    },
    {
      width: 80,
      component: <FilterAltIcon fontSize='small'/>,
      dataKey: 'action',
      align: 'center'
    },
];

function ReviewsPage({title}) {
    const classes = useStyles();
    const { user } = React.useContext(AuthContext);
    const [pullRequests, setPullRequests] = React.useState({opened: 0, reviewed: 0, merged: 0, pull_requests: []});
    const [rowData, setRowData] = React.useState([]);

    const loadPullRequests = async () => {
        try {
            const response = await GetPullRequests(user.id);
            
            if (response.status === 200) {
                setPullRequests(response.data);
            }

        } catch (error) {
            console.error('Failed to fetch pull requests data', error);
        }
    }

    useEffect(() => {
        loadPullRequests();
    }, []);

    useEffect(() => {
        const rows = pullRequests.pull_requests.map((x) => {
          const created_at = new Date(x.created_at);
          
          return {
                id: x.id, 
                title: x.title, 
                author: <TableRowsType.Avatar src={x.github_user.avatar_url} profile_url={x.github_user.profile_url}/>,
                repo: <TableRowsType.Repo name={x.github_repo.name} url={x.github_repo.url}/>,
                created: `${created_at.toLocaleDateString(localeSettings.localeLanguage, localeSettings.localeDateOptions)} ${created_at.toLocaleTimeString(localeSettings.localeLanguage, localeSettings.localeTimeOptions)}`,
                open_comments: <TableRowsType.CommentsSummary count={x.thread_count}/>,
                status: <TableRowsType.Status status={x.state} />,
                action:<TableRowsType.Action url={x.url}/>
            }
        });

        setRowData(rows);

    }, [pullRequests])

    return (
        <div className={classes.container}>
            <Container fixed>
                <Grid container rowSpacing={6} alignItems={'center'} justifyContent='center' spacing={2}>
                    <Grid item xs={3}>
                        <SummaryCard title='Open Diffs' count={pullRequests.opened} label='Opened and in the preview pipeline' />
                    </Grid>
                    <Grid item xs={3}>
                        <SummaryCard title='Reviewed' count={pullRequests.reviewed} label='Reviewed by Ada' />
                    </Grid>
                    <Grid item xs={3}>
                        <SummaryCard title='Merged' count={pullRequests.merged} label='Reviewed and merged' />
                    </Grid>
                </Grid>
                <div style={{height: "36px"}}/>
                <VirtualizedTable columns={columns} data={rowData}/>
            </Container>
        </div>
    );
}

export default ReviewsPage;