// ModalComponent.js
import React from 'react';
import {
    Modal,
    Box,
    FormControl,
    Select,
    MenuItem,
    Card,
    CardMedia,
    CardContent,
    Typography,
    IconButton,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const integrations = [
    {
        name: 'Github Ada App',
        description: 'Description for Integration 1',
        image: `${process.env.PUBLIC_URL}/images/integration-github.png`,
        url: `${process.env.REACT_APP_GITHUB_ADA_APP}`
    },
];

const AddIntegrationModal = ({open, handleClose}) => {
    const [selectedIntegration, setSelectedIntegration] = React.useState('');

    const handleChange = (event) => {
        setSelectedIntegration(event.target.value);
    };

    const selected = integrations.find(integration => integration.name === selectedIntegration);

    const handleAddIntegration = (url) => {
        window.open(url, '_self');
        handleClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Choose Integration
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </Box>
                <FormControl fullWidth sx={{mt: 2}}>
                    <Select
                        value={selectedIntegration}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{'aria-label': 'Without label'}}
                    >
                        <MenuItem value="" disabled>Select an integration</MenuItem>
                        {integrations.map((integration, index) => (
                            <MenuItem key={index} value={integration.name}>
                                {integration.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {selected && (
                    <Card sx={{mt: 2}}>
                        <CardMedia
                            component="img"
                            height="150"
                            image={selected.image}
                            alt={selected.name}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {selected.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {selected.description}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddIntegration(selected.url)}
                    sx={{mt: 2}}
                    fullWidth
                    disabled={!selectedIntegration}
                >
                    Add Integration
                </Button>
            </Box>
        </Modal>
    );
}

export default AddIntegrationModal;