import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const SuccessButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#C2FFC9',
    '&:hover': {
      backgroundColor: '#C2FFC9'
    },
}));

export default SuccessButton;