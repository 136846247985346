import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DrawerFactory } from '../../utils/DrawerFactory';
import { Button } from '@mui/material';

export default function Navbar() {
    const styleProps = { drawerWidth: 250, drawerHeight: '100%' };
    const location = useLocation();
    const navigate = useNavigate();
    const [menuItems, setMenuItems] = React.useState({ topList: [], bottomList: [], title: "", back: false });

    const DrawerList = () => (
        <Box width={styleProps.drawerWidth} height={styleProps.drawerHeight} display={'flex'} flexDirection={'column'} role="presentation" sx={{backgroundColor: '#05B6B7', color: '#fff'}}>
            <Toolbar>
                {
                    !menuItems.back ?
                        <Button size='medium' fullWidth onClick={() => navigate('/')} sx={{color: '#fff', padding: 0}}>
                            <Box display={'flex'} width={'100%'} alignItems={'center'} gap={1}>
                                <img src={`${process.env.PUBLIC_URL}/images/adalabs.png`} width={48} height={48}/>
                                <Typography variant="h7" noWrap component="div" fontWeight={'bold'}>
                                    My Workspace
                                </Typography>
                            </Box>
                        </Button>
                    :
                        <Button size='medium' fullWidth onClick={() => navigate('/')} sx={{color: '#fff'}}>
                            <Box display={'flex'} width={'100%'}>
                                <ArrowBackIosNewIcon style={{ position: 'absolute', left: -12 }}/>
                                <Typography flex={1} variant="h7" noWrap component="div" fontWeight={'bold'}>
                                    { menuItems.title }
                                </Typography>
                            </Box>
                        </Button>
                }
            </Toolbar>
            <Divider/>
            <List style={{flex: 2}}>
                {menuItems.topList.map((l, index) => (
                    <ListItem button disablePadding key={l.name} component={Link} to={l.path}
                              selected={location.pathname === l.path}>
                        <ListItemButton>
                            {
                                l.icon &&
                                <ListItemIcon>
                                    {l.icon}
                                </ListItemIcon>
                            }
                            <ListItemText primary={l.name}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider/>
            <List>
                {menuItems.bottomList.map((l, index) => (
                    <ListItem button disablePadding key={l.name} component={Link} to={l.path} state={{ previousLocation: l.isModal ? location : undefined }}
                              selected={location.pathname === l.path}>
                        <ListItemButton>
                            {
                                l.icon &&
                                <ListItemIcon>
                                    {l.icon}
                                </ListItemIcon>
                            }
                            <ListItemText primary={l.name}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    React.useEffect(() => {
        const path = location.pathname.split("/");
        const drawerFactory = new DrawerFactory();
        drawerFactory.loadMenuItems(path[1]);
        setMenuItems(drawerFactory.getMenuItems());
    }, [location])

    return (
        <div style={{backgroundColor: "#58A6FF"}}>
            <Drawer variant="permanent" anchor="left">
                {DrawerList()}
            </Drawer>
        </div>
    );
}
