import React, {useContext, useEffect, useState} from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import Button from "@mui/material/Button";
import TableComponent from "../components/Shared/TableComponent";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ActivityIndicator from "../components/Shared/ActivityIndicator";
import {AuthContext} from "../context/AuthContext";
import {fetchGithubInstallationRepos, fetchGithubInstallations} from "../api/integrations";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0'
    }
}));

const repoHeaders = ["Name", "Private", "Active", "View"]

function RepositoriesPage({title}) {
    const classes = useStyles();
    const {user} = useContext(AuthContext);
    const [error, setError] = useState('');
    const [installationID, setInstallationID] = useState();
    const [installationRepos, setInstallationRepos] = useState([]);

    useEffect(() => {
        const getInstallations = async () => {
            try {
                const response = await fetchGithubInstallations(user.id)
                if (response.status === 200) {
                    setInstallationID(response.data.id)
                }
            } catch (error) {
                setError('Failed to fetch Github App Installation.');
                console.error('Failed to fetch Github App Installation', error);
            }
        }

        getInstallations();
    }, [])

    useEffect(() => {
        const getInstallations = async () => {
            try {
                const response = await fetchGithubInstallationRepos(installationID)
                if (response.status === 200) {
                    let repos = response.data.repos
                    const mappedRepos = repos.map(r => ({
                        name: r.name,
                        private: r.private.toString(),
                        active: <ActivityIndicator status={r.is_active ? "active" : "inactive"}/>,
                        view: (
                            <Button variant="contained" color="primary" size="small"
                                    onClick={() => console.log("view clicked")}>
                                <KeyboardDoubleArrowRightIcon/>
                            </Button>
                        )
                    }));

                    setInstallationRepos((prevItems) => [...prevItems, ...mappedRepos]);
                }
            } catch (error) {
                setError('Failed to fetch Github Integrations.');
                console.error('Failed to fetch Github Integrations', error);
            }
        }

        getInstallations();
    }, [installationID])

    return (
        <div>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
            <Container fixed>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px'}}>
                    <Button variant="contained" color="primary" onClick={() => console.log("create project clicked")}>
                        Create New App
                    </Button>
                </div>
                <TableComponent headers={repoHeaders} rows={installationRepos}/>
            </Container>
        </div>
    );
}

export default RepositoriesPage;