import * as Node from "./Nodes";
import * as Edge from "./Edges";

export const nodeTypes = {
  source: Node.Source,
  action: Node.Action,
  ['action-elk']: Node.ActionELK,
  end: Node.End
};

export const edgeTypes = {
  'custom-edge': Edge.CustomEdge,
}