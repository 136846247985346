import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MergeIcon from '@mui/icons-material/Merge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LockIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import Web from '@mui/icons-material/Web';
import SchemaIcon from '@mui/icons-material/Schema';
import RepositoriesPage from "../pages/RepositoriesPage";
import TasksPage from "../pages/TasksPage";
import SettingsPage from "../pages/SettingsPage";
import IntegrationsPage from "../pages/IntegrationsPage";
import AccountPage from '../pages/AccountPage';
import { routes } from './Constants';
import { WorkspacePage, UsersPage, RolesPage, SecretsPage, SwitchWorkspaceModal } from '../pages/SettingsPage';
import ReviewsPage from '../pages/ReviewsPage';
import InfrastructurePage from '../pages/InfrastructurePage';
import ServicesPage from '../pages/ServicesPage';

const iconStyle = {color: "#fff"};

const DrawerItems = {
    "main": {
        topList: [
            { name: "Tasks", icon: <AccountTreeIcon sx={iconStyle}/>, path: "/tasks", element: <TasksPage/> },
            { name: "Services", icon: <SchemaIcon sx={iconStyle}/>, path: routes.services, element: <ServicesPage/> },
            { name: "Infrastructure", icon: <Web sx={iconStyle}/>, path: routes.infrastructure, element: <InfrastructurePage/> },
            { name: "Reviews", icon: <WysiwygIcon sx={iconStyle}/>, path: routes.reviews, element: <ReviewsPage/> },
        ],
        bottomList: [
            { name: "Repositories", icon: <MergeIcon sx={iconStyle}/>, path: "/repos", element: <RepositoriesPage/> },
            { name: "Secrets", icon: <LockIcon sx={iconStyle}/>, path: "/secrets", element: <SecretsPage/> },
            { name: "Integrations", icon: <ElectricalServicesIcon sx={iconStyle}/>, path: "/integrations", element: <IntegrationsPage/> },
            { name: "Settings", icon: <SettingsIcon sx={iconStyle}/>, path: "/settings", element: <SettingsPage/> },
            { name: "Account", icon: <AccountCircleIcon sx={iconStyle}/>, path: "/account", element: <AccountPage/> }
        ],
        title: "",
        back: false,
    },
    [routes.settings]: {
        topList: [
            { name: "Workspace", icon: undefined, path: routes.settings_workspace, element: <WorkspacePage/> },
            { name: "Users", icon: undefined, path: routes.settings_users, element: <UsersPage/> },
            { name: "Roles", icon: undefined, path: routes.settings_roles, element: <RolesPage/> },
            { name: "Secrets", icon: undefined, path: routes.settings_secrets, element: <SecretsPage/> },
            { name: "Integrations", icon: undefined, path: routes.settings_integrations, element: <IntegrationsPage/> },
        ],
        bottomList: [
            { name: "Switch Workspace", icon: undefined, isModal: true, path: routes.settings_switch_workspace, element: <SwitchWorkspaceModal/> },
        ],
        title: "Settings",
        back: true
    }
}

export class DrawerFactory {
    items = {};

    constructor() {
        if (typeof DrawerFactory.instance === "object") {
            return DrawerFactory.instance;
        }

        DrawerFactory.instance = this;
        return this;
    }

    getMenuItems = () => {
        return this.items;
    }

    loadMenuItems = (menu) => {
        this.items = DrawerItems[`/${menu}`] || DrawerItems["main"];
    }
}