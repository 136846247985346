import React, {useEffect, useState} from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import TableComponent from "../components/Shared/TableComponent";
import ActivityIndicator from "../components/Shared/ActivityIndicator";
import axios from "axios";
import {convertToLocalTimestamp} from "../utils/Date";
import {useLocation} from 'react-router-dom';
import Button from "@mui/material/Button";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0'
    },
    tableTitle: {
        margin: '20px 0',
        paddingLeft: '20px'
    }
}));

const installationHeaders = ["ID", "Target ID", "Target Type", "Target Name", "User ID", "Active", "Created", "Last Update"]
const repoHeaders = ["ID", "Github Repo ID", "Name", "Private", "Active", "Created", "Last Update"]
const permissionHeaders = ["ID", "Permission Type", "Access Level", "Created", "Last Update"]

function GithubIntegrationPage() {
    const location = useLocation();
    const {user_id, installation_id} = location.state || {};
    const classes = useStyles();
    const [error, setError] = useState('');
    const [activeInstallation, setActiveInstallation] = useState(false);
    const [installations, setInstallations] = useState([]);
    const [installationRepos, setInstallationRepos] = useState([]);
    const [installationPermissions, setInstallationPermissions] = useState([]);

    const fetchGithubInstallations = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GITHUB_URL}/installations`, {
                params: {
                    user_id: user_id,
                    is_active: true,
                },
                withCredentials: true
            });
            if (response.status === 200) {
                setInstallations((prevItems) => [...prevItems, {
                    id: response.data.id,
                    target_id: response.data.target_id,
                    target_type: response.data.target_type,
                    target_name: response.data.target_name,
                    user_id: response.data.user_id,
                    active: <ActivityIndicator status={response.data.is_active ? "active" : "inactive"}/>,
                    created: convertToLocalTimestamp(response.data.created_at),
                    last_update: convertToLocalTimestamp(response.data.updated_at),
                }]);

                if (response.data.is_active) {
                    setActiveInstallation(response.data.is_active)
                }
            }
        } catch (error) {
            setError('Failed to fetch Github Integrations.');
            console.error('Failed to fetch Github Integrations', error);
        }
    };

    const fetchGithubInstallationRepos = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GITHUB_URL}/installations/repos`, {
                params: {
                    installation_id: installation_id,
                    is_active: true,
                },
                withCredentials: true
            });
            if (response.status === 200) {
                let repos = response.data.repos
                const mappedRepos = repos.map(r => ({
                    id: r.id,
                    github_repo_id: r.github_repo_id,
                    name: r.name,
                    private: r.private.toString(),
                    active: <ActivityIndicator status={r.is_active ? "active" : "inactive"}/>,
                    created: convertToLocalTimestamp(r.created_at),
                    last_update: convertToLocalTimestamp(r.updated_at),
                }));

                setInstallationRepos((prevItems) => [...prevItems, ...mappedRepos]);
            }
        } catch (error) {
            setError('Failed to fetch Github Integrations.');
            console.error('Failed to fetch Github Integrations', error);
        }
    };

    const fetchGithubInstallationPermissions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_GITHUB_URL}/installations/permissions`, {
                params: {
                    installation_id: installation_id,
                },
                withCredentials: true
            });
            if (response.status === 200) {
                let permissions = response.data.permissions
                const mappedPermissions = permissions.map(p => ({
                    id: p.id,
                    permission_type: p.permission_type,
                    access_level: p.access_level,
                    created: convertToLocalTimestamp(p.created_at),
                    last_update: convertToLocalTimestamp(p.updated_at),
                }));

                setInstallationPermissions((prevItems) => [...prevItems, ...mappedPermissions]);
            }
        } catch (error) {
            setError('Failed to fetch Github Integrations.');
            console.error('Failed to fetch Github Integrations', error);
        }
    };

    useEffect(() => {
        fetchGithubInstallations()
    }, [])

    useEffect(() => {
        if (activeInstallation) {
            fetchGithubInstallationRepos()
            fetchGithubInstallationPermissions()
        }
    }, [activeInstallation])

    return (
        <div>
            <Typography variant="h4" className={classes.title}>Github Integration</Typography>
            <Container fixed>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '15px', paddingRight: '25px'}}>
                    <Button variant="contained" color="secondary" onClick={() => window.open(`${process.env.REACT_APP_GITHUB_ADA_APP}`, '_blank')}>
                        Uninstall
                    </Button>
                </div>
                <Container fixed>
                    <Typography variant="h5" className={classes.tableTitle}>Installation</Typography>
                    <TableComponent headers={installationHeaders} rows={installations}/>
                </Container>
                <Container fixed>
                    <Typography variant="h5" className={classes.tableTitle}>Repos</Typography>
                    <TableComponent headers={repoHeaders} rows={installationRepos}/>
                </Container>
                <Container fixed>
                    <Typography variant="h5" className={classes.tableTitle}>Permissions</Typography>
                    <TableComponent headers={permissionHeaders} rows={installationPermissions}/>
                </Container>
            </Container>
        </div>
    );
}

export default GithubIntegrationPage;