import React from 'react';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: null }}
    />
))({
    '& .MuiTabs-flexContainer': {
      gap: '4px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
});
  
export const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{border: '1px solid #000', borderRadius: '8px', borderTopLeftRadius: '0px', padding: '12px'}}
        >
            <div style={{border: '1px solid #000', borderRadius: '8px', fontSize: '14px'}}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        </div>
    );
}