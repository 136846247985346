import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {TableVirtuoso} from 'react-virtuoso';
import {IconButton} from '@mui/material';

const StatusColors = {
    ["reviewed"]: '#05B6B7',
    ["merged"]: '#C2C2C2',
    ["in review"]: '#FF9900',
    ["open"]: '#FF9900',
    ["closed"]: '#C2C2C2',
}

const SeverityColors = {
    ["critical"]: '#FF0000',
    ["warning"]: '#FF9900',
    ["none"]: '#C2C2C2'
}

const navigation = (url) => {
    window.open(url, "_blank")
}

export const TableRowsType = {
    Avatar: ({src, profile_url}) => <Avatar src={src} sx={{
        width: 32,
        height: 32,
        marginLeft: 'auto',
        marginRight: 'auto',
        cursor: 'pointer'
    }} onClick={() => navigation(profile_url)}/>,
    Repo: ({name, url}) => <div style={{
        padding: '4px',
        backgroundColor: '#05B6B7',
        borderRadius: '4px',
        fontWeight: '600',
        textAlign: 'center',
        color: '#fff',
        cursor: 'pointer'
    }} onClick={() => navigation(url)}><span>{name}</span></div>,
    CommentsSummary: ({count}) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: count > 0 ? '#FF0505' : '#C2C2C2',
                borderRadius: 32,
                fontWeight: '600',
                color: '#fff',
                height: 32,
                width: 32
            }}>
                <span>{count}</span>
            </div>
        </div>
    ),
    Status: ({status}) => <div style={{
        padding: '4px',
        backgroundColor: StatusColors[status.toLowerCase()],
        borderRadius: '4px',
        fontWeight: '600',
        textAlign: 'center',
        color: '#fff'
    }}><span>{status}</span></div>,
    Action: ({url}) => <IconButton onClick={() => navigation(url)}><OpenInNewIcon fontSize='small'
                                                                                  sx={{color: '#05B6B7'}}/></IconButton>,

    Service: ({image_name, service_name}) => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4}}>
            <img src={`${process.env.PUBLIC_URL}/images/${image_name}`} width={16} height={16}/>
            <div style={{
                padding: '4px',
                backgroundColor: '#05B6B7',
                borderRadius: '4px',
                fontWeight: '600',
                textAlign: 'center',
                color: '#fff'
            }}><span>{service_name}</span></div>
        </div>
    ),
    Impact: ({level}) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: level > 0 ? '#FF0505' : '#C2C2C2',
                borderRadius: 24,
                fontWeight: '600',
                color: '#fff',
                height: 24,
                width: 24
            }}>
                <span>{level}</span>
            </div>
        </div>
    ),
    Severity: ({severity}) => <div style={{
        padding: '4px',
        backgroundColor: SeverityColors[severity.toLowerCase()],
        borderRadius: '4px',
        fontWeight: '600',
        textAlign: 'center',
        color: '#fff'
    }}><span>{severity}</span></div>
}

const VirtualizedTable = ({
                              columns = [],
                              data = [],
                              sx = {height: 400},
                              tableCellStyle = {},
                              rowContentStyle = {},
                              hover = false,
                              onClick = undefined
                          }) => {

    const handleClick = (props) => {
        if (onClick) onClick(props.item, props["data-index"]);
    }

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref}/>
        )),
        Table: (props) => (
            <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}}/>
        ),
        TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref}/>),
        TableRow: React.forwardRef((props, ref) => <TableRow {...props} ref={ref} hover={hover}
                                                             onClick={() => handleClick(props)}/>),
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref}/>),
    };

    const fixedHeaderContent = () => {
        return (
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        variant="head"
                        align={column.align || 'left'}
                        style={{width: column.width}}
                        sx={{
                            backgroundColor: '#000',
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            padding: '4px 16px',
                            lineHeight: '16px',
                            ...tableCellStyle
                        }}
                    >
                        {column.label || column.component}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    const rowContent = (_index, row) => {
        return (
            <React.Fragment>
                {columns.map((column) => (
                    <TableCell
                        key={column.dataKey}
                        align={column.align || 'left'}
                        sx={{fontSize: '12px', padding: '4px 16px', ...rowContentStyle}}
                    >
                        {row[column.dataKey]}
                    </TableCell>
                ))}
            </React.Fragment>
        );
    }

    return (
        <Paper style={{width: '100%', ...sx}}>
            <TableVirtuoso
                data={data}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
            />
        </Paper>
    );
}

export default VirtualizedTable;