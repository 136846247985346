import React from 'react';
import {createUseStyles} from 'react-jss';
import { useDrop } from 'react-dnd';
import TaskCard from './TaskCard'
import { taskTypes } from '../../utils/Constants';

const useStyles = createUseStyles((theme) => ({
    cardContainer: { backgroundColor: '#000', borderRadius: 8},
    cardTitle: { color: '#fff', textAlign: 'center', padding: '4px 8px', fontWeight: '600', fontSize: '20px' },
    cardBody: { display: 'flex', flexDirection: 'column', backgroundColor: '#CFCECE', alignItems: 'center', gap: 8, padding: '12px', borderRadius: '0 0 4px 4px', minHeight: '60px'},
}));

const TaskColumn = ({title, updateTaskItem, type, items}) => {
    const classes = useStyles();
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [taskTypes.todo, taskTypes.in_progress, taskTypes.review, taskTypes.done],
        drop: (item) => updateTaskItem(item.id, type),
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
    });

    return (
        <div ref={drop} className={classes.cardContainer}>
            <div className={classes.cardTitle}><span>{title}</span></div>
            <div className={classes.cardBody}>
                {
                    items.map((item) => 
                        <TaskCard key={item.id} id={item.id} type={type}/>
                    )
                }
            </div>
        </div>
    );
}

export default TaskColumn;