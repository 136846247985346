import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const PrimaryButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#C2E5FF',
    '&:hover': {
      backgroundColor: '#C2E5FF'
    },
}));

export default PrimaryButton;