import React from 'react';
import {Container, Typography} from '@mui/material';
import {createUseStyles} from 'react-jss';
import InputAdornment from '@mui/material/InputAdornment';
import { InputBase } from '@mui/material';
import { Delete } from '@mui/icons-material';
import IconButton from "@mui/material/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableComponent from "../../../components/Shared/TableComponent";
import { AuthContext } from "../../../context/AuthContext";
import { fetchSecretsByWorkspaceId } from "../../../api/integrations";

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    }
}));

const headers = ["Key", "Value", "Created", ""]

function SecretsPage({title}) {
    const classes = useStyles();
    const { currentWorkspace } = React.useContext(AuthContext);
    const [rows, setRows] = React.useState([]);
    const [showPassword, setShowPassword] = React.useState({});

    const handleClickShowPassword = (key) => {
        showPassword[key] = !showPassword[key];
        setShowPassword({...showPassword});
    }

    const handleMouseDownPassword = (e) => {
        console.log("handleMouseDownPassword")
        e.preventDefault();
    };

    const handleDelete = (e) => {
        /** Not implemented */
    }

    const loadInfo = async () => {
        try {
            const response = await fetchSecretsByWorkspaceId(currentWorkspace);
            
            if (response.status === 200) {
                const { secrets } = response.data;
                setRows(secrets);
            }

        } catch (error) {
            console.error('Failed to fetch secrets data', error);
        }
    }

    const renderRows = () => (
        rows.map((x) => (
            <TableRow key={x.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">{x.key}</TableCell>
                <TableCell align="center">
                    <InputBase value={x.value}
                        id={x.id}
                        type={showPassword[x.id] ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => handleClickShowPassword(x.id)}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword[x.id] ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        sx={{fontSize: '14px'}}
                        readOnly
                    />
                </TableCell>
                <TableCell align="center">{x.created_at}</TableCell>
                <TableCell align="center">
                    <IconButton size="small" onClick={handleDelete}>
                        <Delete/>
                    </IconButton>
                </TableCell>
            </TableRow>
        ))
    )

    React.useEffect(() => {
        loadInfo();
    }, [])

    return (
        <div>
            <Typography variant="h5" className={classes.title}>{title}</Typography>
            <Container maxWidth='md'>
                <TableComponent headers={headers} rows={renderRows} rowsAsComponent={true}/>
            </Container>
        </div>
    );
}

export default SecretsPage;