import axios from "axios";

export const fetchGithubInstallations = async (userID) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_GITHUB_URL}/installations`, {
            params: {
                user_id: userID,
                is_active: true,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching GitHub installations:", error);
        throw error;
    }
};

export const fetchGithubInstallationRepos = async (installationID) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_GITHUB_URL}/installations/repos`, {
            params: {
                installation_id: installationID,
                is_active: true,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching GitHub repos:", error);
        throw error
    }
};

export const fetchWorkspacesByUserId = async (user_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_WORKSPACES}/workspaces`, {
            params: {
                user_id: user_id,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching Workspaces:", error);
        throw error
    }
};

export const fetchWorkspacesById = async (workspace_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_WORKSPACES}/workspaces`, {
            params: {
                workspace_id: workspace_id,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching Workspaces:", error);
        throw error
    }
};

export const fetchWorkspaceUsersByWorkspaceId = async (workspace_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_WORKSPACES}/workspaces/users`, {
            params: {
                workspace_id: workspace_id,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching workspace users:", error);
        throw error
    }
}

export const fetchRolesByWorkspaceId = async (workspace_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_WORKSPACES}/workspaces/roles`, {
            params: {
                workspace_id: workspace_id,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching roles:", error);
        throw error
    }
}

export const fetchSecretsByWorkspaceId = async (workspace_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_WORKSPACES}/workspaces/secrets`, {
            params: {
                workspace_id: workspace_id,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching secrets:", error);
        throw error
    }
}

export const fetchIntegrationsByWorkspaceId = async (workspace_id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_WORKSPACES}/workspaces/integrations`, {
            params: {
                workspace_id: workspace_id,
            },
            withCredentials: true
        });
        return response;
    } catch (error) {
        console.error("Error fetching integrations:", error);
        throw error
    }
}

export const patchAccounts = async (id, last_used_workspace_id = "", workspaces = []) => {
    try {
        const response = await axios.patch(`${process.env.REACT_APP_ACCOUNTS_URL}/accounts`, {
            id,
            last_used_workspace_id,
            workspaces
        }, { withCredentials: true });
        return response;
    } catch (error) {
        console.error("Error patch accounts:", error);
        throw error
    }
}