import React, {createContext, useState} from 'react';

// Create the context
const GlobalContext = createContext();

// Provider component
const GlobalProvider = ({children}) => {

    const bookServiceDate = new Date(Date.UTC(2024, 4, 15));
    const bookFormattedDate = `${bookServiceDate.getUTCMonth()}/${bookServiceDate.getUTCDate()}/${bookServiceDate.getUTCFullYear()}`;
    const productServiceDate = new Date(Date.UTC(2024, 4, 17));
    const productFormattedDate = `${productServiceDate.getUTCMonth()}/${productServiceDate.getUTCDate()}/${productServiceDate.getUTCFullYear()}`;
    const [apps, setApps] = useState([
        {name: "Book Service", createDt: bookFormattedDate, status: "running"},
        {name: "Product Service", createDt: productFormattedDate, status: "running"},
    ]);

    const [newApp, setNewApp] = useState({});

    const contextValue = {
        apps,
        setApps,
        newApp,
        setNewApp,
    };

    return (
        <GlobalContext.Provider value={contextValue}>
            {children}
        </GlobalContext.Provider>
    );
};

// Export the GlobalContext for useContext in other components
export {GlobalContext, GlobalProvider};