import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function AppDetails({ app }) {
    return (
        <Card sx={{ minWidth: 275, margin: 2 }}>
            <CardContent>
                <Typography variant="h5" component="div">
                    {app.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Status: {app.status}
                </Typography>
                <Typography sx={{ mt: 2 }} color="text.secondary">
                    Last Updated: {app.createDt}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default AppDetails;
