import React from 'react';
import {Grid} from '@mui/material';
import {Container, Box, Button} from '@mui/material';
import {createUseStyles} from 'react-jss';
import Add from '@mui/icons-material/Add';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TaskColumn from '../components/NewAppComponents/TaskColumn';
import { taskTypes } from '../utils/Constants'; 

const useStyles = createUseStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '20px 0',
    }
}));

const defaultTaskItems = [
    {
        id: 1,
        type: taskTypes.todo
    },
    {
        id: 2,
        type: taskTypes.todo
    },
    {
        id: 3,
        type: taskTypes.todo
    },
    {
        id: 4,
        type: taskTypes.in_progress
    }
]

function Tasks({title}) {
    const classes = useStyles();
    const [taskItems, setTaskItems] = React.useState([...defaultTaskItems]);

    const updateTaskItem = (id, type) => {
        const index = defaultTaskItems.findIndex(x => x.id === id);
        defaultTaskItems[index].type = type;
        setTaskItems([...defaultTaskItems]);
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Container fixed>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} width={'100%'} sx={{margin: '24px 0px', padding: '24px 24px 0px'}}>
                    <Button variant='contained' size='small' startIcon={<Add/>} sx={{backgroundColor: '#01BFBF'}}>Workflow</Button>
                </Box>
                <Box display={'flex'} flexDirection={'column'} sx={{padding: '24px', background: '#fff', borderRadius: '4px'}}>
                    <Grid container rowSpacing={12} spacing={4} sx={{flex: 1}}>
                        <Grid item xs={3}>
                            <TaskColumn title={'Todo'} type={taskTypes.todo} items={taskItems.filter(x => x.type === taskTypes.todo)} updateTaskItem={updateTaskItem}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TaskColumn title={'In Progress'} type={taskTypes.in_progress} items={taskItems.filter(x => x.type === taskTypes.in_progress)} updateTaskItem={updateTaskItem}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TaskColumn title={'Review'} type={taskTypes.review} items={taskItems.filter(x => x.type === taskTypes.review)} updateTaskItem={updateTaskItem}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TaskColumn title={'Done'} type={taskTypes.done} items={taskItems.filter(x => x.type === taskTypes.done)} updateTaskItem={updateTaskItem}/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </DndProvider>
    );
}

export default Tasks;