import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import LoadingSpinner from "../components/Shared/LoadingSpinner";

const PrivateRoute = ({ children }) => {
    const { auth } = useContext(AuthContext);

    if (auth.loading) {
        return <LoadingSpinner size={50} color="primary" message="Loading..."/>;
    }

    return auth.isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
