import React from 'react';
import { Box, styled } from '@mui/material';

// Define colors for each status
const statusColors = {
    active: '#32CD32',
    pending: '#FFA500',
    failed: '#FF0000',
    inactive: '#808080'
};

const StatusCircle = styled(Box)(({ status }) => ({
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: statusColors[status] || 'grey',
    display: 'inline-block'
}));

function ActivityIndicator({ status }) {
    return <StatusCircle status={status} />;
}

export default ActivityIndicator;
