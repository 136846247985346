import React from 'react';
import {
    ReactFlow,
    Controls,
    Background,
    useReactFlow
} from '@xyflow/react';

const RF = {
    Custom: React.forwardRef((props, ref) => {
        const { fitView } = useReactFlow();

        React.useImperativeHandle(ref, () => ({
            fitView() {
                fitView();
            }
        }));

        return (
            <ReactFlow
                {...props}
            >
                <Background/>
                <Controls>
                    {props.children}
                </Controls>
            </ReactFlow>
        )
    })
}

export default RF;